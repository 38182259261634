import React from "react";
import Tag from "../../../Components/Tags/Tag";
import "./UsersCreateValue.scss";
import icon2 from "../Assets/icon2.png";
import icon3 from "../Assets/icon3.png";
import icon1 from "../Assets/icon1.png";

export default function UsersCreateValue() {
  return (
    <section id="UsersCreateValue-section" className="py-5">
      <div className="large-container ">
        <div className="d-flex h-100 align-items-center justify-content-end w-100 container-main gap-3 flex-column text-center">
          <div className="text-center txt-container d-flex  flex-column ">
            <div className="d-flex m-auto">
              {" "}
              <Tag
                background="linear-gradient(45deg, #FF9C42, #C236FF)"
                color="#000"
                uppertitle="MARKETING STRATEGY"

              />
            </div>
            <h2
              className="font-size-80 white-color
             font-iner-bold line-height-1-1 mb-0"
            >
              Users create Value.
            </h2>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-md-4 col-12">
            <div className="top-card h-100 left-col p-lg-4 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
              <div>
                {" "}
                <img src={icon1} className="img-fluid mb-3 smaller-rocket"   alt="" />
              </div>
              <div className="font-iner-bold font-size-40 white-color line-height-1">
              Higher Users No.<br></br>
from Game<br></br>
Developers &<br></br>
External Casinos
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12 ">
            <div className="">
              <div className="top-row d-flex mb-md-4 mb-3  flex-md-nowrap flex-wrap" >
                <div className="three-col-card me-3 grey-background mt-md-0 mt-3  p-lg-4 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
                  <div>
                    {" "}
                    <img className="img-fluid mb-3" src={icon2} alt="" />
                  </div>
                  <div className="font-iner-bold font-size-40 white-color line-height-1">
                  Userbase Expansion 
with Airdrops

                  </div>
                </div>
                <div className="five-col-card ms-md-2 ms-0 mt-md-0 mt-3  p-lg-4 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
                  <div className="font-iner-bold font-size-40 white-color line-height-1">
                    Passive Lifetime <br></br> Income Will Increase Member Numbers
                  </div>
                </div>
              </div>
              <div className="bottom-row d-flex mt-r flex-md-nowrap flex-wrap">
                <div className="four-col-card me-md-3 me-0 mb-md-0 mb-3 third-background  p-lg-4 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
                  <div className="font-iner-bold font-size-40 white-color line-height-1">
                    Streamers’ Subscribers PlayGround
                  </div>
                </div>
                <div className="four-col-card ms-md-2  ms-0  p-lg-4 p-md-3 p-4 rounded-24 d-flex flex-column grey-background justify-content-center">
                  <div className="font-iner-bold font-size-40 white-color line-height-1">
                    <div>
                      <img className="img-fluid mb-3" src={icon3} alt="" />
                    </div>
                    Real Casino <br></br> Players will join
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
