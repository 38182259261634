import React from "react";
import Tag from "../../../Components/Tags/Tag";

export default function PerfectStrategy() {
  return (
    <>
      <div
        id="perfect-strategy-section "
        className="large-container my-5 py-md-5"
      >
        <div className="d-flex justify-content-center align-conten-center mt-5">
          <Tag
            background="linear-gradient(45deg, #FF9C42, #C236FF)"
            color="#000"
            uppertitle="Token Management"
          />
        </div>
        <div
          style={{ maxWidth: "808px" }}
          className="font-size-80 font-iner-bold mx-auto text-center line-height-1"
        >
          The perfect strategy to Grow $LIPO
        </div>
        <div
          style={{ maxWidth: "1650" }}
          className="font-size-80 font-iner-medium mx-auto text-center line-height-1 mt-5"
        >
          <span className="color-gradient-lipo">Ensure Offer & Demand.</span>{" "}
          Create Liquidity. Increase the Users.{" "}
          <span className="color-gradient-blue">Increase $LIPO Value. </span>
          <span className="color-gradient-purple">Protect the Price</span> Play
          & Enjoy.
        </div>
      </div>
    </>
  );
}
