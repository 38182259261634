import React from "react";
import "./PlatformGrowFaster.scss";
import Tag from "../../../Components/Tags/Tag";

export function PlatformGrowFaster() {
  return (
    <>
      <div id="platform-grow-section">
        <div className="large-container">
          <div className="text-center txt-container d-flex flex-column ">
            <div className="d-flex m-auto">
              {" "}
              <Tag
                background="linear-gradient(45deg, #FF9C42, #C236FF)"
                color="#000"
                uppertitle="$LIPO"
              />
            </div>
            <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-5">
              <span className="color-gradient-lipo ">Grow faster</span>
              <br></br>
              with Game Marketplace
            </h2>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="full-width card-project mb-4 p-lg-5 p-md-4 p-3 rounded-24">
                <div className="d-flex justify-content-end flex-column h-100">
                  <div className="font-size-40 white-color font-iner-bold line-height-1">
                    Own a game & <br />
                    Earn money <br />
                    from players.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="left-col card-project p-lg-5 p-md-4 p-3 rounded-24 mb-4 d-flex justify-content-end flex-column">
                <div className="font-size-40 white-color font-iner-bold line-height-1">
                  Develop & <br />
                  Sell your game
                  <br />
                  in Game Marketplace.
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="right-col card-project p-lg-5 p-md-4 p-3 rounded-24 mb-4 d-flex justify-content-end flex-column">
              <div className="font-size-40 white-color font-iner-bold line-height-1">
                  Develop & <br />
                  Add your game
                  <br />
                  & Earn Money.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlatformGrowFaster;
