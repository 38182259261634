import React, { useRef, useState } from "react";
// import SliderRight from '../../SliderRight/SliderRight';
import Title from "../../../Components/Title/Title";
import Description from "../../../Components/Description/Description";

// import SliderButtons from '../../SliderRight/SliderButtons/SliderButtons';
import NewsSlide from "./NewsSlide/BuiltForSlide";
import { blog, builtFor } from "../../../Globals/globalBlog";
import SliderRight from "../../../Components/SliderRight/SliderRight";
import SliderButtons from "../../../Components/SliderRight/SliderButtons/SliderButtons";
import Tag from "../../../Components/Tags/Tag";
import BuiltForSlide from "./NewsSlide/BuiltForSlide";

const LatestNews = () => {
  const sliderRef = useRef(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const id = window.location.pathname.split("/")[2];
  return (
    <section id="builtFor-seciton" className="section-interspace large-container  my-5">
      <div className="d-flex h-100 align-items-start justify-content-center w-100 container-main gap-3 flex-column">
        <div className=" txt-container d-flex  flex-column ">
          <div className="d-flex">
            {" "}
            <Tag
              background="linear-gradient(45deg, #FF9C42, #C236FF)"
              color="#000"
              uppertitle="LIVEPOT"
            />
          </div>
          <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-5">
          Built for:
          </h2>
        </div>{" "}
  
      </div>

      <SliderRight
        sliderRef={sliderRef}
        atStart={atStart}
        atEnd={atEnd}
        setAtEnd={setAtEnd}
        setAtStart={setAtStart}
      >
        {builtFor
         
          .map((el, i) => {
            return (
              <React.Fragment key={i}>
                <BuiltForSlide
                  img={el.bg}
                  title={el.title}
                  subtitle={el.shortDescription}
           
                />
              </React.Fragment>
            );
          })}
      </SliderRight>
    </section>
  );
};

export default LatestNews;
