import React, { useRef, useState } from "react";
import "./SliderComp.scss";
import Slider from "react-slick";
import { ReactComponent as LeftArrow } from "../../Assets/Global/left-arrow-slider.svg";
import { ReactComponent as RightArrow } from "../../Assets/Global/right-arrow-slider.svg";

const SliderComp = ({ data, autoPlay, setContentIndex, sliderImg }) => {
  const [slideIndex, setSlideIndex] = useState(() => 0);

  const sliderRef = useRef();

  const sliderEnd = data.length - 1;

  const waitForNextSlide = (newIndex, nextBtn) => {
    setTimeout(() => {
      if (newIndex < 0) {
        newIndex = sliderEnd;
      } else if (newIndex > sliderEnd) {
        newIndex = 0;
      }

      if (sliderRef.current) {
        // sliderRef.current.slickGoTo(newIndex);
        if (nextBtn === true) {
          sliderRef.current.slickNext();
        } else sliderRef.current.slickPrev();
      }

      if (setContentIndex) setContentIndex(newIndex);
      setSlideIndex(newIndex);
    }, 250);

    return () => clearTimeout(waitForNextSlide);
  };

  const settings = {
    className: "center",
    arrows: false,

    draggable: true,

    variableWidth: true,
    slidesToShow: 1,
    speed: 250,
    centerMode: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => waitForNextSlide(next),
    // afterChange: next => { updateIndex(next); console.log(next) },
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings} ref={sliderRef}>
        {data.map((boxItem) => (
          <div key={boxItem.id} className="slick-item">
            <div
              key={boxItem.id}
              className={`text-white slider-box ${boxItem.className}`}
            >
              {boxItem.id === 4 ? boxItem.image : null}

              {boxItem?.title && (
                <h3 className="font-size-28 font-iner-bold mb-5 text-center">
                  {boxItem.title}
                </h3>
              )}

              {/* <h4 className="text-center active-box-title mb-0">{boxItem.title}</h4> */}
            </div>
          </div>
        ))}
      </Slider>

      {/* <div className="w-100 d-flex align-items-center justify-content-center mb-auto custom-margin">
        <LeftArrow
          className="me-4 cursor-pointer left-arrow"
          onClick={() => {
            waitForNextSlide(slideIndex - 1, false);
          }}
        />
        <RightArrow
          alt="arrow"
          className="cursor-pointer right-arrow"
          onClick={() => {
            waitForNextSlide(slideIndex + 1, true);
          }}
        />
      </div> */}
    </>
  );
};

export default SliderComp;
