import React from "react";
// import "./Homepage.scss";
import Hero from "./TokenHero/HomepageHero";
import Footer from "../../Components/Footer/Footer";

import Tokenomics from "../Homepage/Tokenomics/Tokenomics";

import FirstToken from "./FirstToken/FirstToken";
import UseLipo from "./UseLipo/UseLipo";
import TokenManagement from "./TokenManagement/TokenManagement";
import TokenDistribution from "./TokenDistribution/TokenDistribution";
import Mechanics from "./Mechanics/Mechanics";
import BuiltFor from "./BuiltFor/BuiltFor";
import Technology from "./Technology/Technology";
import PlatformHelp from "../Platform/PlatformHelp/PlatformHelp";
const TokenPage = () => {
  return (
    <div className="homepage white-color s">
      <Hero />
      <FirstToken />
      <UseLipo />
      <BuiltFor />
      <Technology />
      <TokenManagement />
      <Mechanics />
      <Tokenomics />
      <TokenDistribution />
      <PlatformHelp />
      <Footer />
    </div>
  );
};

export default TokenPage;
