import React from "react";
import Title from "../../../Components/Title/Title";
import "./FirstToken.scss";
import Button from "../../../Components/Button/Button";
import people from "./Assets/comunity.png";
import Tag from "../../../Components/Tags/Tag";
import haltera from "../Assets/haltere.png";
export default function FirstToken() {
  return (
    <>
            <div id="first-token-section" className="mt-5">
      <div className="large-container">

          <div className="text-center txt-container d-flex flex-column ">
            <div className="d-flex m-auto">
              {" "}
              <Tag
                background="linear-gradient(45deg, #FF9C42, #C236FF)"
                color="#000"
                uppertitle="LIVEPOT"
              />
            </div>
            <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-4">
            The first token
            <br></br>
in the world.
            </h2>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="full-width card-project mb-4 p-lg-5 p-md-4 p-3 rounded-24">
                <div className="d-flex justify-content-center flex-column h-100">
                  <Title title="$LIPO" size={48} />
                  <Title title="Unique strategy " size={48} />

                  <div className="font-size-48 white-color font-iner-bold mb-3">
              -  <span className="color-gradient-lipo ">  Designed to grow{" "}</span>     
                  </div>
                  
<div>
                  <Button
                    title="WhitePaper"
                    height={48}
                    heightResponsive={48}
                    heightResponsive580={40}
                    minWidth580={150}
                    width={150}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
                    hoverColor="#fff"
                    bgHoverColor="#08FF9B"
                    customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                    callback={(e) => {
                      window.open("https://livepot.s3.eu-central-1.amazonaws.com/Livepot/Whitepaper/LivePotWhitepaper.pdf", "_blank");
                  }}
                  /></div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="left-col card-project p-lg-5 p-md-4 p-3 rounded-24 mb-4 d-flex justify-content-center flex-column">
             <div>  <img className="img-fluid mb-4" src={people} alt="" /></div> 
                <Title
                  title="Full Utility
Huge community."
                  size={48}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="right-col card-project p-lg-5 p-md-4 p-3 rounded-24 mb-4 position-relative">
                <div className="font-size-48 color-gradient-lipo font-iner-bold">
                  It has a minimum value
                </div>
                <div className="inner-container-layer-1">
                  <div className="position-relative h-100 w-100">
                    {" "}
                    <div className="font-iner-bold lipo-purple font-size-48 pos-abs-lipo">$LIPO</div>
                    <div className="font-iner-bold font-size-48 pos-abs-price text-center">10$ 
                    <div className="font-size-16 font-iner-light purple-grey-color">
                      SECOND YEAR
                    </div>
                    
                    </div>
                  </div>

                  <div className="inner-container-layer-2">
                    <div className="d-flex h-100 align-items-center justify-content-center ">
                      {" "}
                      <div className="d-flex align-items-baseline">
                      <div className="font-size-40 font-iner-bold white-color">5$ </div>{" "}
                      <div className="fon-iner-light font-size-16 purple-grey-color ms-1">
                        {" "}FIRST YEAR
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
