import React from "react";
import "./ButtonOutline.scss";
import { MdOutlineKeyboardArrowDown as ArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft as ArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight as ArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowUp as ArrowUp } from "react-icons/md";
import { toast } from "react-toastify";
import {FiArrowUpRight} from 'react-icons/fi'

const ARROWS = {
  up: <ArrowUp />,
  down: <ArrowDown />,
  left: <ArrowLeft />,
  right: <ArrowRight />,
};

const ButtonOutline = ({
  title,
  height,
  heightResponsive,
  heightResponsive580,
  color,
  outlineColor,
  bgColor,
  hoverColor,
  callback,
  bgHoverColor,
  image,
  arrow,
  align,
  specialPadding,
  fontSize,
  disableBtn,
  width,
  minWidth,
  minWidth991,
  minWidth580,
  customClass,
  icon,
}) => {
  const getArrow = (arrow) => {
    if (Object.keys(ARROWS).includes(arrow)) return ARROWS[arrow];
    return null;
  };

  return (
    <button
      onClick={(e) =>
        typeof callback === "function"
          ? callback(e)
          : toast.info("Coming soon!", { toastId: "coming-soon" })
      }
      className={`d-flex 
                        align-items-center 
                        justify-content-${align || "center"} 
                        button-outline-style-btn
                        ${disableBtn ? "disabled" : ""}
                        ${customClass ? customClass : ""}`}
      disabled={disableBtn ? true : false}
      style={{
        background: `${bgColor || "transparent"}`,
        border: `1px solid ${outlineColor || "#000000"}`,
        width: width ? `${width}px` : "unset",
        "--minWidth": minWidth ? `${minWidth}px` : "174px",
        "--minWidth991": minWidth991 ? `${minWidth991}px` : "unset",
        "--minWidth580": minWidth580 ? `${minWidth580}px` : "unset",
        "--hoverColor": `${hoverColor || "#000000"}`,
        "--bgHoverColor": `${bgHoverColor || bgColor || "transparent"}`,
        "--color": `${color || "#000000"}`,
        "--height": `${height || 48}px`,
        "--heightResponsive": `${heightResponsive || 36}px`,
        "--heightResponsive580": `${heightResponsive580 || 36}px`,
        "--special-padding": `${specialPadding || "0px 10px"}`,
      }}
    >
      <span
        className={`font-iner-regular 
                    ${fontSize ? fontSize : "font-size-18"}
                    d-flex 
                    align-items-center 
                    justify-content-center 
                    gap-2
                    text-unselectable
                    `}
      >
        {image && <span className="image-btn">{image}</span>}
        {typeof title === "undefined" ? "Click here" : title}
        {arrow && getArrow(arrow)}
        {icon && <img className="icon-btn" src={icon} alt="Icon" />}
        {/* <FiArrowUpRight color="#fff" size={20}/> */}
      </span>
      <button className="button-outline-btn "></button>
    </button>
  );
};

export default ButtonOutline;
