import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './BuiltForSlide.scss';


const MAX_CHARS = 300;
const MAX_CHARS_TITLE = 100;

const BuiltForSlide= ({ ...props }) => {
  return (
    <div


      className="slide radius16 overflow-hidden h-100 user-select-auto text-decoration-none"
      id="built-for-slider"
    >
      <img
        style={{ minHeight: '60%' }}
        src={props.img}
        alt="news img"
        className="img-fluid img-blog"
      />
      <div className="d-flex p-3 position-relative main-content-container align-items-start justify-content-start gap-2 flex-column h-100">
        <div className="d-flex w-100  align-items-center justify-content-between gap-2">

        </div>
        <span className="font-size-24 font-iner-bold white-color lh-1">
          {/* {props.title.substring(0, MAX_CHARS_TITLE)}
          {props.title.length > MAX_CHARS_TITLE ? '...' : ''} */}
          {props.title}
        </span>
        <span className="font-size-16 font-iner-regular gray-color">
          {props.subtitle.substring(0, MAX_CHARS)}
          {props.subtitle.length > MAX_CHARS ? '...' : ''}
        </span>
      </div>
    </div>
  );
};

export default BuiltForSlide;
