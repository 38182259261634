import React, { useRef, useState } from "react";
import Title from "../../../Components/Title/Title";
import SliderRight from "../../../Components/SliderRight/SliderRight";
import Tag from "../../../Components/Tags/Tag";
import heart from "..//Assets/heart.png";
import smile from "..//Assets/smile.png";
import market from "..//Assets/market.png";
import built from "..//Assets/built.png";
import token from "../Assets/token_sus.png";

const MobileCarousel = () => {
  const sliderRef = useRef(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);

  return (
    <section
      id="mechanics-carousel"
      className="section-interspace large-container  "
    >
   
      <SliderRight
        sliderRef={sliderRef}
        atStart={atStart}
        atEnd={atEnd}
        setAtEnd={setAtEnd}
        setAtStart={setAtStart}
        extraClass="slider-right-tokenomics"
      >
         <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="d-flex">
            <div className="first-child d-flex">
              <div className="mt-auto">
                {" "}
                <div className="d-flex w-auto">
                  {" "}
                  <Tag
                          background="linear-gradient(45deg, #FF9C42, #C236FF)"
                          color="#000"
                          uppertitle="$LIPO"
                        />
                </div>
                <Title
                  title="Mechanism to Protect $LIPO and"
                  size={32}
                />
<div className="font-size-48 lipo-gradient font-iner-bold color-gradient-lipo line-height-1-1">
                        {" "}
                        Increase the Price
                      </div>              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="d-flex">
            <div className="first-child d-flex">
              <div className="mt-auto">
                {" "}
                <div className="d-flex w-auto">
                  {" "}
                  <Tag
                    background="linear-gradient(45deg, #E0FF36, #FF9C42)"
                    color="#000"
                    uppertitle="UNIQUE"
                  />
                </div>
                <Title
                  title="Technically
                        Built not to be Sold Under Minimum Value."
                  size={32}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="first-child d-flex">
            <div className="mt-auto">
              <img src={heart} className="mb-3 img-fluid" alt="" />
              <Title title="Creating Trust" size={32} />
              <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-3">
                If the price drops, negative opinions can lead to a further
                decrease in price as more people may sell at the minimum price.
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="first-child d-flex">
            <div className="mt-auto">
              <img src={smile} className="mb-3 img-fluid" alt="" />

              <Title title="No Negative Opinion" size={32} />
              <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-3">
                If the price drops, negative opinions can lead to a further
                decrease in price as more people may sell at the minimum price.
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="first-child d-flex">
            <div className="d-flex">
              <div className="mt-auto">
                {" "}
                <div className="d-flex">
                  {" "}
                  <Tag
                    background="linear-gradient(45deg, #E0FF36, #FF9C42)"
                    color="#000"
                    uppertitle="UNIQUE"
                  />
                </div>
                <Title
                  title="Everybody is 
                        able to put for sale 
                        higher than the Minimum Value."
                  size={32}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="first-child d-flex">
            <div className="mt-auto">
              <img src={market} className="mb-3 img-fluid" alt="" />

              <Title
                title="NO Market
Manipulation."
                size={32}
              />
              <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-3">
                If the price drops, negative opinions can lead to a further
                decrease in price as more people may sell at the minimum price.
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="first-child d-flex">
            <div className="mt-auto">
              <img src={built} className="mb-3 img-fluid" alt="" />
              <Title title="Built To Grow" size={32} />
              <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-3">
                Players can easily calculate their funds as USDT, allowing them
                to purchase $LIPO with $1000 and play with the chance to win
                more.
              </div>
            </div>
          </div>
        </div>
      </SliderRight>
    </section>
  );
};

export default MobileCarousel;
