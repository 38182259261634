import React, { useState, useEffect } from "react";
import "./TokenManagement.scss";
import { Row } from "react-bootstrap";
import Tag from "../../../Components/Tags/Tag";

import Title from "../../../Components/Title/Title";
import features from "../Assets/unique-features.jpg";
import passively from "../Assets/tm-community.jpg";
import minimum from "../Assets/minimum-fixed.png";
import pricing_drop from "../Assets/tm-eliminate.jpg";
import w_license from "../Assets/playground-license.jpg";
import p2p from "../Assets/p2p-livepot.jpg";
import playWithCredit from "../Assets/play-with-credit.jpg";
import shareholder from "../Assets/shareholder.png";
import marketPlace from "../Assets/marketplace.png";

const TokenManagement = () => {
  const [current, setCurrent] = useState(0);
  const imgArray = [
    features,
    pricing_drop,
    w_license,
    passively,
    shareholder,
    //
    marketPlace,

    p2p,
    playWithCredit,
    minimum,
  ];

  const [data, setData] = useState([
    {
      title: "Protection for Holders",
      description:
        "The world’s first project developed based on the European Projects model. The successful execution of the project is ensured through a Bank Guarantee Letter, which covers the funds in case the project encounters issues or the company developing it is unable to complete it. ",
      current: true,
      id: 1,
    },
    {
      title: "Pricing drop protection",
      description: `The Price Drop Protection feature safeguards your earnings from price slippage due to unforeseen circumstances. With this feature, you can have peace of mind knowing that your earnings will always be according to your work. Experience betting with confidence and maximize your profits by taking advantage of this innovative feature. `,
      current: false,
      id: 2,
    },
    {
      title: `Be a casino without a license`,
      description: `The platform allows anyone to become a casino owner and offer games without requiring a license. With our platform, you’ll have access to all the necessary licenses and infrastructure, so you can focus on what you do best - owning the game.`,
      current: false,
      id: 3,
    },
    {
      title: `Passively earn money for a lifetime`,
      description: `LivePot will employ a standard affiliate system for our most-performing users that will ensure lifelong commissions from the new users brought by them into the platform.`,
      current: false,
      id: 4,
    },
    {
      title: `Ranking System for Bonus & Credit`,
      description: `This System is tailored to provide rewards to players based on their loyalty and gameplay. By playing more, users can increase their credit limit & deposit bonuses, while unlocking exciting rewards based on their wagered and deposits.`,
      current: false,
      id: 5,
    },
    {
      title: `Casino & Games Marketplace`,
      description: `The marketplace provided by the platform enhances the overall betting and gaming experience by offering a dedicated area for developers and content creators to showcase their work and earn more revenue.`,
      current: false,
      id: 6,
    },
    {
      title: `P2P Bet Room`,
      description: `An exclusively designed betting opportunity where any platform streamer can open a room where they can play while other users place bets on the winning team or  particular action that may happen during the game.
      `,
      current: false,
      id: 7,
    },
    {
      title: `Play with Credit in any Real Casino`,
      description: `In the near future, a unique opportunity will be available, which involves $LIPO being utilized as credit in physical casinos. This is made possible due to the fact that $LIPO is verifiable and cannot be replicated, making it an exclusive and secure payment method.
      `,
      current: false,
      id: 8,
    },
    {
      title: `Minimum Fixed Value        `,
      description: `$LIPO has been designed to have a minimum value of $5 for the first year and $10 for the second year to ensure that it maintains its value as a token for actions and games within the LivePot platform.
        `,
      current: false,
      id: 9,
    },
  ]);
  const handleClick = (item) => {
    setCurrent(item.id - 1);

    const newData = data.map((d) => {
      if (d.id === item.id) {
        return { ...d, current: true };
      } else {
        return { ...d, current: false };
      }
    });
    setData(newData);
  };

  return (
    <section id="TokenManagement-section" className="my-5">
      <div className="section-padding white-color">
        <div className="large-container">
          <div className="d-block d-lg-none"></div>
          <Row>
            <div className="col-lg-6 col-12 order-lg-1 order-2 d-flex justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center justify-content-lg-start align-items-lg-start develop-container">
                <Tag
                  background="linear-gradient(45deg, #FF9C42, #C236FF)"
                  color="#000"
                  uppertitle="TOKEN"
                />
                <h2 className="font-size-56 mb-3 font-iner-bold line-height-1-1  text-md-start text-center mb-3">
                  <span className="yellow-color-gradient">Unique</span> features
                </h2>
                <div className="in-section-scroll">
                  {data.map((item, index) => (
                    <>
                      {" "}
                      <p
                        onClick={() => {
                          handleClick(item);
                        }}
                        className={`${
                          item.current ? "active" : "innactive"
                        } font-size-16 mt-3   font-iner-regular position-relative light-grey text-center mb-3 text-lg-start line-height-1-4`}
                      >
                        {/* {displayData.description} */}
                        <hr className="position-absolute top-line"></hr>
                        <div className="py-3">
                          {" "}
                          <div className="mb-2 d-flex justify-content-lg-start justify-content-center">
                            {" "}
                            <Title size={24} title={item.title} />{" "}
                            <div className="d-flex ms-2">
                              {" "}
                              <Tag
                                background="linear-gradient(45deg, #FE9F42, #E0FF36)"
                                color="#000"
                                uppertitle="UNIQUE"
                                height="14px"
                                width="46px"
                                fontSize="9px"
                              />
                            </div>
                          </div>
                          {item.description}{" "}
                        </div>
                        <hr className="position-absolute"></hr>
                      </p>
                    </>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-12 order-lg-2 order-1 pb-lg-0 pb-5 position-relative slider-col-height d-flex justify-content-center align-items-center">
              <img
                className={`img-fluid mh-mob-img img`}
                src={imgArray[current]}
                alt=""
              />
            </div>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default TokenManagement;
