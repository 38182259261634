import React from "react";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import SliderComp from "../../../../Components/Slider/SliderComp";
import Tag from "../../../../Components/Tags/Tag";
import Button from "../../../../Components/Button/Button";

import PlusIcon from "../Assets/plus-icon.png";
import RealWinnersIcon from "../Assets/real-winners.png";
import TexasHoldemIcon from "../Assets/cards.png";
import LiveCasinoLaptopImg from "../Assets/live-casino-laptop-screen.png";
import MysteryBoxImg from "../Assets/incentive-v2.png";
import EsportsImg from "../Assets/esports.png";
import LivestreamBetsImg from "../Assets/livestream-bets.png";
import BoltIcon from "../../Assets/bolt.svg";
import "./PlatformToggleContentCards.scss";

const PlatformToggleContentCards = ({ tabKey }) => {
  function getContent(key) {
    switch (key) {
      case "live-casino":
        return {
          title: "Live Casino",
          descripton: `The platform provides the opportunity to step into the world of live casinos and experience the thrill of playing in real-time through live streaming.`,
          image: LiveCasinoLaptopImg,
          imgCustomClass: "live__casino",
          rightImageNormalFlow: false,
          bgImage: "live-casino",
        };
      case "livestream-bets":
        return {
          title: "LiveStream Bets",
          descripton: `A unique way to provide players with real-time betting opportunities on a variety of casino games through a live stream. This feature allows players to engage with gameplay as it happens, providing an exciting and convenient option for those who enjoy live casino games.`,
          image: LivestreamBetsImg,
          imgCustomClass: "livestream__bets",
          rightImageNormalFlow: false,
          bgImage: "livestream-bets",
        };
      case "play-to-earn":
        return {
          title: "Play to Earn",
          descripton: `A variety of entertaining games enable users to accumulate cryptocurrency rewards as they play more and more.`,
          bgImage: "play-to-earn",
          sliderData: [
            {
              id: 0,
              // image: (
              //   <img
              //     src={CashWinnerIcon}
              //     loading="lazy"
              //     alt="Daily&Weekly Cash Winner"
              //   />
              // ),
              title: "Giveaway",
              className: "giveaway-bg",
            },
            {
              id: 1,
              // image: (
              //   <img
              //     src={BingoTournamentsIcon}
              //     loading="lazy"
              //     alt="New Bingo Tournaments"
              //   />
              // ),
              title: "Bingo",
              className: "bingo-bg",
            },
            {
              id: 2,
              image: (
                <img src={RealWinnersIcon} loading="lazy" alt="Real Winners" />
              ),
              title: "Achievements",
              className: "achievements-bg",
            },
            {
              id: 3,
              // image: (
              //   <img
              //     src={MachinesIcon}
              //     loading="lazy"
              //     alt="Over 300+ Machines"
              //   />
              // ),
              title: "Incentives",
              className: "incentives-bg",
            },
            {
              id: 4,
              image: (
                <div className="icon__tag">
                  <Tag
                    background="linear-gradient(90deg, #ff9c42, #c236ff)"
                    color="#000"
                    uppertitle="JUNE 2024"
                  />
                  <img
                    src={PlusIcon}
                    loading="lazy"
                    alt="Game Availability Icon"
                  />
                </div>
              ),
              title: "All Games Available at Launch.",
              className: "notify__card background-lipo-gradient",
            },
          ],
        };
      case "multiplayer-games":
        return {
          title: "Multiplayer Games",
          descripton: `Multiplayer casino games provide an interactive and social experience where players can compete simultaneously, offering real-time gameplay and high payouts. Popular for both novice and experienced players, these games range from classics to innovative options.`,
          bgImage: "multiplayer-games",
          sliderData: [
            {
              id: 0,
              // image: (
              //   <img src={TexasHoldemIcon} loading="lazy" alt="Texas Hold'em" />
              // ),
              title: "Texas Hold'em",
              className: "texas-holdem-bg",
            },
            {
              id: 1,
              image: (
                <img
                  src={TexasHoldemIcon}
                  loading="lazy"
                  alt="Three Card Poker"
                />
              ),
              title: "Three Card Poker",
              className: "three-card-poker-bg",
            },
            {
              id: 2,
              // image: (
              //   <img src={BackgammonIcon} loading="lazy" alt="Backgammon" />
              // ),
              title: "Backgammon",
              className: "backgammon-bg",
            },
            {
              id: 3,
              // image: <img src={ChessIcon} loading="lazy" alt="Chess" />,
              title: "Chess",
              className: "chess-bg",
            },
            {
              id: 4,
              image: (
                <div className="icon__tag">
                  <Tag
                    background="linear-gradient(90deg, #ff9c42, #c236ff)"
                    color="#000"
                    uppertitle="JUNE 2024"
                  />
                  <img
                    src={PlusIcon}
                    loading="lazy"
                    alt="Game Availability Icon"
                  />
                </div>
              ),
              title: "All Games Available at Launch.",
              className: "notify__card background-lipo-gradient",
            },
          ],
        };
      case "p2p-bet-room":
        return {
          title: "P2P Bet Room",
          descripton: `An exclusively designed betting opportunity where any platform streamer can open a room where they can play while other users place bets on the winning team or particular action that may happen during the game.`,
          // image: StackImg,
          imgCustomClass: "p2p",
          hasSecondTag: true,
          rightImageNormalFlow: true,
          bgImage: "p2p-bet-room",
        };
      case "sporting-bets":
        return {
          title: "Sports Betting",
          descripton: `A wide range of sports betting will be available on our easy-to-use platform. LivePot will offer competitive odds for performing users to make their betting experience even more exciting.`,
          // image: SportsImg,
          imgCustomClass: "sport__bets",
          rightImageNormalFlow: true,
          bgImage: "sporting-bets",
        };
      case "esports":
        return {
          title: "eSports",
          descripton: `eSports betting is a fast-growing trend where players can place wagers on competitive video gaming matches involving professional gamers or teams. Betting options include in-game item betting, live streaming, and fantasy betting.`,
          image: EsportsImg,
          imgCustomClass: "esports",
          rightImageNormalFlow: false,
          bgImage: "esports",
        };
      case "mystery-box":
        return {
          title: "Mystery Box",
          descripton: `Mystery Box is a thrilling feature that offers a chance to win exciting rewards by opening a virtual box with randomized content. The drop chance adds an element of surprise, making each box-opening experience unique.`,
          image: MysteryBoxImg,
          rightImageNormalFlow: true,
          imgCustomClass: "mystery__box",
          bgImage: "mystery-box",
        };
      default:
        return {
          title: "Casino Games",
          descripton: `A varied collection of traditional casino games that will suit every user's betting preference and taste.`,
          bgImage: "casino",
          sliderData: [
            {
              id: 0,
              // image: <img src={SlotsIcon} loading="lazy" alt="Slots" />,
              title: "Slots",
              className: "slots-bg",
            },
            {
              id: 1,

              // image: <img src={BlackJackIcon} loading="lazy" alt="Blackjack" />,
              title: "Blackjack",
              className: "blackjack-bg",
            },
            {
              id: 2,
              // image: <img src={RouletteIcon} loading="lazy" alt="Roulette" />,
              title: "Roulette",
              className: "roulette-bg",
            },
            {
              id: 3,
              // image: <img src={CrapsIcon} loading="lazy" alt="Craps" />,
              title: "Craps",
              className: "craps-bg",
            },
            {
              id: 4,
              image: (
                <div className="icon__tag">
                  <Tag
                    background="linear-gradient(90deg, #ff9c42, #c236ff)"
                    color="#000"
                    uppertitle="JUNE 2024"
                  />
                  <img
                    src={PlusIcon}
                    loading="lazy"
                    alt="Game Availability Icon"
                  />
                </div>
              ),
              title: "All Games Available at Launch.",
              className: "notify__card background-lipo-gradient",
            },
          ],
        };
    }
  }

  const tabContent = getContent(tabKey);

  return (
    <section
      id="platform-toggle-content-cards"
      className={`platform-toggle-content-cards__bg--${
        tabContent.bgImage || ""
      }`}
    >
      <div className="large-container">
        <Row>
          <Col md={3} sm={12} className="column__left">
            <div className="tags__container">
              <Tag
                background="linear-gradient(90deg, #ff9c42, #c236ff)"
                color="#000"
                uppertitle="LIVEPOT"
                className="test"
              />
              {tabContent.hasSecondTag && (
                <Tag
                  background="linear-gradient(270deg, #FF9C42 0%, #E0FF36 100%)"
                  color="#000"
                  uppertitle="UNIQUE"
                />
              )}
            </div>
            <h2 className="font-size-64 font-iner-bold">{tabContent.title}</h2>

            <p className="font-size-18 font-iner-regular light-grey mw-586">
              {tabContent.descripton}
            </p>
            <div className="d-flex">
              <Button
                title="Play Now"
                height={48}
                heightResponsive={48}
                heightResponsive580={40}
                width={150}
                color="#fff"
                outlineColor="transparent"
                bgColor="linear-gradient(90deg, #ff9c42 0%,  #c236ff 100%) 0% 0% no-repeat padding-box "
                hoverColor="#fff"
                bgHoverColor="#08FF9B"
                customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                callback={(e) => toast.warning("Coming Soon")}
                icon={BoltIcon}
              />
            </div>
          </Col>

          <Col md={6} className="column__right">
            {tabContent.sliderData ? (
              <div className="position-relative custom__shadow">
                <div
                  className={`container-full-width ${
                    tabContent.slideCustomClass || ""
                  }`}
                >
                  <SliderComp data={tabContent.sliderData} autoPlay />
                </div>
              </div>
            ) : (
              <div className="position-relative">
                <div
                  className={`container-full-width ${
                    tabContent.rightImageNormalFlow
                      ? "normal__flow"
                      : "fluid__flow"
                  } ${tabContent.imgCustomClass}`}
                >
                  <img src={tabContent.image} />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default PlatformToggleContentCards;
