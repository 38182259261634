import React from "react";
import Tag from "../../../Components/Tags/Tag";
import "./Roadmap.scss";
import roadmap from "../Assets/roadmap.png";
import ScrollContainer from 'react-indiana-drag-scroll'

export default function Roadmap() {
  return (
    <section id="Roadmap-section" className="  my-5">
      
      <div className="large-container">
        <div className="d-flex h-100 align-items-center justify-content-center w-100 container-main gap-3 flex-column">
          <div className="text-center txt-container d-flex  flex-column ">
            <div className="d-flex m-auto">
              {" "}
              <Tag
                background="linear-gradient(45deg, #FF9C42, #C236FF)"
                color="#000"
                uppertitle="LIVEPOT"
              />
            </div>
            <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-0 white-color">
Roadmap            </h2>
          </div>

        </div>
       
      </div>
      <div>
        <ScrollContainer className="scroll-container"  style={{cursor:'grab'}}>
          <div className="roadmap-container position-relative w-100">
  

            <div className="div">
              <img className="roadmap roadmap-img" src={roadmap} alt="" />
            </div>
      

          </div>
          </ScrollContainer></div>
    </section>
  );
}
