import React from "react";

import { Row } from "react-bootstrap";
import Title from "../../../Components/Title/Title";
import Tag from "../../../Components/Tags/Tag";
import PlayMoreIcon from "./Assets/playmore.png";
import BlackDiamondRank from "./Assets/black-diamond-rank.png";
import CardIcon from "./Assets/card.png";
import ThrophyIcon from "./Assets/trophy.png";
import LivePotLogo from "./Assets/livepot-logo.png";
import "./PlatformRanking.scss";

const PlatformRanking = () => {
  return (
    <section id="platform-ranking-section">
      <div className="large-container">
        <div className="cd-flex h-100 align-items-center justify-content-end w-100 container-main gap-3 flex-column text-centerol-12">
          <div className="title-box">
            <Tag
              background="linear-gradient(90deg, #ff9c42, #c236ff)"
              color="#000"
              uppertitle="LIVEPOT"
            />
            <h2 className="font-iner-bold font-size-80">Ranking system</h2>
          </div>
        </div>

        <Row>
          <div className="col-lg-6 col-md-12">
            <div className="card2x background-lipo-gradient">
              <Row>
                <div className="col-sm-6 col-12 d-flex align-items-center align-items-sm-start align-self-lg-center justify-content-md-start justify-content-center">
                  <div className="card2x-left">
                    <img
                      src={PlayMoreIcon}
                      className="img-fluid"
                      alt="Play More Icon"
                    />
                    <Title title="Play More to Unlock More." size={40} />
                  </div>
                </div>
                <div className="column-modifier col-sm-6 col-12">
                  <div className="card2x-right">
                    <img
                      src={BlackDiamondRank}
                      className="img-fluid"
                      alt="Balck Diamond Icon"
                    />
                  </div>
                </div>
              </Row>
            </div>

            <Row>
              <div className="col-sm-6">
                <div className="card1x__container rounded-24">
                  <div className="card1x__container--left">
                    <img src={CardIcon} className="img-fluid" alt="Card Icon" />
                    <Title title="More Bonus for deposit." size={40} />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card1x__container rounded-24">
                  <div className="card1x__container--right">
                    <img
                      src={ThrophyIcon}
                      className="img-fluid"
                      alt="Throphy Icon"
                    />
                    <h3
                      className=" 
                    title-component z-2 lh-1 font-iner-bold font-size-40"
                    >
                      More <span>Prizes.</span>
                    </h3>
                  </div>
                </div>
              </div>
            </Row>
          </div>

          <div className="margin-b-none col-lg-3 col-md-12">
            <div className="card2x-long rounded-24">
              <div className="card2x-long--content">
                <img
                  src={LivePotLogo}
                  className="img-fluid"
                  alt="Icon Play More"
                />

                <h3 className="font-size-36 line-height-1-2 font-iner-bold">
                  First platform developed to
                </h3>
                <h3 className="font-size-36 lipo-gradient line-height-1-2  font-iner-bold color-gradient-lipo">
                  play on Credit.
                </h3>
                <h3 className="font-size-36 line-height-1-2  font-iner-bold">
                  Increase your chance to Win.
                </h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12 mb-5">
            <Row>
              <div className=" col-lg-12 col-sm-6 col-12">
                <div className="card1x-right rounded-24">
                  <div className="font-size-40 color-gradient-blue font-iner-bold line-height-1-1">
                    Advance in rank to
                    <br /> increase your limits.
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-sm-6 col-12">
                <div className="card1x-right-bot rounded-24">
                  <div className="font-size-40 color-white font-iner-bold line-height-1-2">
                    More benefits playing with $LIPO.
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default PlatformRanking;
