import React from "react";
import { Button } from "react-bootstrap";
import Tag from "../../../Components/Tags/Tag";
import Title from "../../../Components/Title/Title";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import token from "../Assets/token_sus.png";
import { ReactComponent as Heart } from "../Assets/heart.svg";
import { ReactComponent as Built } from "../Assets/built.svg";
import { ReactComponent as Smile } from "../Assets/smile.svg";
import { ReactComponent as Market } from "../Assets/market.svg";

import "./Mechanics.scss";
import MobileCarousel from "./MobileCarousel";

export default function Mechanics() {
  return (
    <div className="large-container">
      <div id="mechanics-section" className=" my-5">
        <div className="row mb-4 d-lg-block d-none">
          <div className="row">
            <div className="col-lg-3 col-12 px-2">
              <div className="full-height-col rounded-24">
                <div className="left-col card-project rounded-24     ">
                  <img src={token} className="img-fluid" />
                </div>

                <div className="mb-3">
                  <div className="left-col card-project px-md-5 px-2 rounded-24    ">
                    <div className="d-flex">
                      {" "}
                      <Tag
                        background="linear-gradient(45deg, #FF9C42, #C236FF)"
                        color="#000"
                        uppertitle="$LIPO"
                      />
                    </div>
                    <Title
                      title="Mechanism to Protect $LIPO and"
                      size={48}
                    />
                    <div className="font-size-48 lipo-gradient font-iner-bold color-gradient-lipo line-height-1-1">
                      Increase the Price
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-2">
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="d-flex">
                    <div className="mt-auto">
                      {" "}
                      <div className="d-flex">
                        {" "}
                        <Tag
                          background="linear-gradient(45deg, #E0FF36, #FF9C42)"
                          color="#000"
                          uppertitle="UNIQUE"
                        />
                      </div>
                      <Title
                        title="Technically
                        Built not to be Sold Under Minimum Value."
                        size={32}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="mt-auto">
                    <Heart className="mb-3" />
                    <Title title="Creating Trust" size={32} />
                    <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-2">
                      If the price drops, negative opinions can lead to a
                      further decrease in price as more people may sell at the
                      minimum price.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-2">
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="mt-auto">
                    <Smile className="mb-3" />
                    <Title title="No Negative Opinion" size={32} />
                    <div className="font-size-18 gray-color font-iner-regulat mt-2 line-height-1-2">
                      If the price drops, negative opinions can lead to a
                      further decrease in price as more people may sell at the
                      minimum price.
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="d-flex">
                    <div className="mt-auto">
                      {" "}
                      <div className="d-flex">
                        {" "}
                        <Tag
                          background="linear-gradient(45deg, #E0FF36, #FF9C42)"
                          color="#000"
                          uppertitle="UNIQUE"
                        />
                      </div>
                      <Title
                        title="Everybody is 
                        able to put for sale 
                        higher than the Minimum Value."
                        size={32}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-2">
              {" "}
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="mt-auto">
                    <Market className="mb-3" />

                    <Title
                      title="NO Market
Manipulation."
                      size={32}
                    />
                    <div className="font-size-18 gray-color mt-2 font-iner-regulat line-height-1-2">
                      If the price drops, negative opinions can lead to a
                      further decrease in price as more people may sell at the
                      minimum price.
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="mt-auto">
                    <Built className="mb-3" />
                    <Title title="Built To Grow" size={32} />
                    <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-2">
                      Players can easily calculate their funds as USDT, allowing
                      them to purchase $LIPO with $1000 and play with the chance
                      to win more.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 d-lg-none d-block">
          <MobileCarousel />
        </div>
      </div>
    </div>
  );
}
