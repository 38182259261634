import React, { useState, useEffect } from "react";
import "./HeroComponent.scss";
import { toast } from "react-toastify";
import Tag from "../../../Components/Tags/Tag";
import Button from "../../../Components/Button/Button";
import { Row } from "react-bootstrap";
import BoltIcon from "../../Platform/Assets/bolt.svg";

const HeroComponent = ({ img }) => {
  return (
    <section id="project-hero" className="pt-5">
      <div className="h-100">
        <div className="large-container mb-4">
          <Row className="h-100">
            <div className=" col-md-6 col-12 order-md-1 order-2 d-flex justify-content-center align-items-center mb-xl-5 mb-5 mt-md-5">
              <div className="d-flex flex-column mb-md-5 justify-content-center align-items-center justify-content-md-start align-items-md-start develop-container">
                <Tag
                  background="linear-gradient(45deg, #FF9C42, #C236FF)"
                  color="#000"
                  uppertitle="Gaming Platform"
                />
                <h2 className="font-size-80 mb-5 white-color font-iner-bold line-height-1 mb-0 li text-md-start text-center mw-1500-580">
                  The first project<br></br>
                  <span className="color-gradient-lipo">
                    built with unique features.
                  </span>
                </h2>

                <div className="d-flex">
                  <Button
                    title="Get $LIPO"
                    height={48}
                    heightResponsive={48}
                    heightResponsive580={40}
                    width={150}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
                    hoverColor="#fff"
                    bgHoverColor="#08FF9B"
                    customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                    callback={(e) => {
                      //open new link in new tab
                      window.open('https://swap.livepot.com')
                    }}
                    icon={BoltIcon}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12 order-md-2 order-1 pb-lg-0 pb-5 position-relative  ">
              <div className="d-flex justiy-content-center w-100 h-100">
                <img className="img-fluid m-auto" src={img} alt="" />
              </div>
            </div>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default HeroComponent;
