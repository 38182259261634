
import coin from './assets/license.png';
import plug from './assets/passive.png';
import dealer from './assets/ranking.png';
import gameAquisition from './assets/marketplace.png';
import staking from './assets/ruleta.png';
export const builtForUsers = [
  {
    bg: coin,
    title: (
      <>Be a Casino <br></br>
      without a license    </>
    ),
    slug: 'coin-forces-miguel-oliveira-to-sit-out-argentina-grand-prix',
    shortDescription: `The platform allows anyone to become a casino owner and offer games without requiring a license. With our platform, you’ll have access to all the necessary licenses and infrastructure, so you can focus on what you do best - owning the game.  `,
    tags: ['MotoGP', 'RNF', 'RNF Racing', 'CryptoDATA', 'Portimao, Portugal'],
    date: 'March 27, 2023',
    
    article: [
      // {
      //   type: 'image',
      //   content: cryptodata
      // }
    ]
  }, {
    bg: plug,
    title: (
      <>Passive Earn <br></br> Money for a Lifetime    </>
    ),
    extraClass:'background-lipo-gradient ',
    slug: 'coin-forces-miguel-oliveira-to-sit-out-argentina-grand-prix',
    shortDescription: `LivePot will employ a standard affiliate system for our most-performing users that will ensure lifelong commissions from the new users brought by them into the platform. `,
    tags: ['MotoGP', 'RNF', 'RNF Racing', 'CryptoDATA', 'Portimao, Portugal'],
    date: 'March 27, 2023',
    article: [
   

      // {
      //   type: 'image',
      //   content: cryptodata
      // }
    ]
  }, {
    bg: dealer,
    title: (
      <>Ranking System<br></br>
      for Bonus & Credit    </>
    ),
    slug: 'coin-forces-miguel-oliveira-to-sit-out-argentina-grand-prix',
    shortDescription: `This system is tailored to provide rewards to players based on their loyalty and gameplay. By playing more, users can increase their credit limit & deposit bonuses, while unlocking exciting rewards based on their wagered and deposits. `,
    tags: ['MotoGP', 'RNF', 'RNF Racing', 'CryptoDATA', 'Portimao, Portugal'],
    date: 'March 27, 2023',
  
  }, {
    bg: gameAquisition,
    title: (
      <>Casino & Games Marketplace    </>
    ),
    slug: 'coin-forces-miguel-oliveira-to-sit-out-argentina-grand-prix',
    shortDescription: `The platform’s marketplace completes the betting and gaming experience with a space designed for developers and content creators to share their work and increase earnings. `,
    tags: ['MotoGP', 'RNF', 'RNF Racing', 'CryptoDATA', 'Portimao, Portugal'],
    date: 'March 27, 2023',
    article: [
    
  

      // {
      //   type: 'image',
      //   content: cryptodata
      // }
    ]
  }, {
    bg: staking,
    title: (
      <>Wide range
      of Games     </>
    ),
    slug: 'coin-forces-miguel-oliveira-to-sit-out-argentina-grand-prix',
    shortDescription: `A wide range of sports betting will be available on our easy-to-use platform. LivePot will offer competitive odds for performing users to make their betting experience even more exciting.`,
    tags: ['MotoGP', 'RNF', 'RNF Racing', 'CryptoDATA', 'Portimao, Portugal'],
    date: 'March 27, 2023',
    article: [
      {
        type: 'text',
        //   subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        content: (
          <>
           Lisbon, Portugal - Local hero and MotoGP rider Miguel Oliveira will not be participating in the upcoming Argentina Grand Prix due to a recent coin. Following his crash at the Portuguese Grand Prix, Oliveira underwent further testing in his hometown where further damage have been revealed, which doesn’t allow him to start the second GP of the season.

            <br></br>
            <br></br>
            Despite the setback, Oliveira remains committed to his goal of being competitive in the World Championship and will focus on his recovery in order to return to the saddle of his Aprilia RS-GP at round three of the season in Austin, Texas just two weeks later.

            <br></br>
            <br></br>
            The entire CryptoDATA RNF MotoGP team wishes Oliveira a speedy recovery and looks forward to his return to racing. He will not be replaced in Argentina.

            <br></br>
            <br></br>
            Updates regarding his condition will be released by the CryptoDATA RNF MotoGP Team in the near future.
            <br></br>
            <br></br>
          </>
        )
      },
  

      // {
      //   type: 'image',
      //   content: cryptodata
      // }
    ]
  },
  





];
