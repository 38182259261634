import React from "react";

import Tag from "../../../Components/Tags/Tag";
import Button from "../../../Components/Button/Button";
import BoltIcon from "../Assets/bolt.svg";
import { toast } from "react-toastify";
import "./PlartformPlayWithCrypto.scss";
import CardsImg from "./Assets/cards2.png";
import TokenImg from "./Assets/token-front.png";
import CoinsImg from "./Assets/coins.png";
import TokenSideImg from "./Assets/token-side.png";
import Card1 from "./Assets/layer1.png";
import Card2 from "./Assets/layer2.png";
import Card3 from "./Assets/layer3.png";
// import NewNFTs from "../NewNFTs/NewNFTs";

export function PlartformPlayWithCrypto() {
  return (
    <section id="platform-play-with-crypto-section">
      {/* to break */}
      {/* <div className="section1">test</div> */}
      <div className="background-section">
        <div className="large-container">
          <div className="cards__container row">
            <div className="col-md-6 col-12">
              <div className="title__container d-flex flex-column ">
                <div className="d-flex justify-content-md-start justify-content-center">
                  <Tag
                    background="linear-gradient(45deg, #FF9C42, #C236FF)"
                    color="#000"
                    uppertitle="LIVEPOT"
                  />
                </div>
                <h2 className="font-size-56 font-iner-bold line-height-1-1 mb-md-5 mb-0 text-md-start text-center">
                  Play with Crypto <br />
                  in Real Casinos.
                </h2>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="description__container d-flex flex-column align-items-md-start align-items-center">
                <p className="font-iner-regular text-md-start text-center">
                  A varied collection of traditional casino games that will suit
                  every user’s betting preference, desire and taste. A varied
                  collection of traditional casino games that will suit every
                  user’s betting preference, desire and taste.
                </p>
                <Button
                  title="Play now"
                  height={48}
                  heightResponsive={48}
                  heightResponsive580={40}
                  width={150}
                  color="#fff"
                  outlineColor="transparent"
                  bgColor="linear-gradient(90deg, #ff9c42 0%,  #c236ff 100%) 0% 0% no-repeat padding-box "
                  hoverColor="#fff"
                  bgHoverColor="#08FF9B"
                  customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                  callback={(e) => toast.warning("Coming Soon")}
                  icon={BoltIcon}
                />
              </div>
            </div>
          </div>
          {/* <div className="img__container row">
            <div className="col-12">
              <div className="img__container--cards">
                <img
                  className="img-fluid cards__img"
                  src={CardsImg}
                  alt="CardsWithTokenImg"
                />
              </div>
            </div>
          </div> */}

          {/* new section */}

          <div className="cards-container position-relative">
            <div className="card-container card-1">
              <img
                src={Card1}
                alt=""
                className="img-fluid"
                height={"380px"}
                width={"380px"}
              />
            </div>
            <div className="card-container right position-absolute card-2">
              <img
                src={Card2}
                alt=""
                className=" img-fluid "
                height={"400px"}
                width={"400px"}
              />
            </div>
            <div className="card-container left position-absolute card-3">
              <img
                src={Card3}
                alt=""
                className=" img-fluid "
                height={"400px"}
                width={"400px"}
              />
            </div>
          </div>
          {/* end new section */}
        </div>
      </div>
      <div className="container-md">
        <div className="cryptocurrencies__container row">
          <div className="cryptocurrencies__container--img">
            <img
              className="img-fluid token__img"
              src={TokenImg}
              alt="CardsWithTokenImg"
            />
          </div>

          <div className="cryptocurrencies__container--left col-md-6 col-12">
            <div className="details__container row d-flex align-items-start">
              <div className="img__container col-xl-6 col-12 d-flex justify-content-center order-xl-first order-last">
                <img className="img-fluid" src={CoinsImg} alt="CoinsImg" />
              </div>
              <div className="col-xl-6 col-12 text-xl-start text-center">
                <span className="font-size-36 font-iner-bold line-height-1-2 color-gradient-lipo">
                  20% Commission to $LIPO Holders.
                </span>
              </div>
            </div>
            <div className="text__container row">
              <div className="col-12">
                <h3 className="font-size-32 font-iner-bold text-xl-start text-center">
                  Other cryptocurrencies
                </h3>
                <p className="font-iner-regular font-size-18 line-height-1-2 text-xl-start text-center">
                  LivePot is a platform that enables users to play and bet with
                  various types of cryptocurrencies. Our aim is to provide a
                  fair and equitable chance for all users to win exciting
                  rewards and prizes within our platform.
                </p>
              </div>
            </div>
          </div>
          <div className="cryptocurrencies__container--right col-md-6 col-12">
            <div className="details__container row d-flex align-items-start">
              <div className="col-xl-6 col-12 text-xl-start text-center line-height-1-2">
                <span className="font-size-36 font-iner-bold line-height-1-2 color-gradient-lipo">
                  More bonuses.
                </span>
                <span className="font-size-36 font-iner-bold line-height-1-2 color-gradient-lipo">
                  More credit.
                </span>
                <span className="font-size-36 font-iner-bold line-height-1-2 color-gradient-lipo">
                  More cashback.
                </span>
                <span className="font-size-36 font-iner-bold line-height-1-2 color-gradient-lipo">
                  More benefits.
                </span>
              </div>
              <div className="img__container col-xl-6 col-12 d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src={TokenSideImg}
                  alt="TokenSideImg"
                />
              </div>
            </div>
            <div className="text__container row">
              <div className="col-12">
                <h3 className="font-size-32 font-iner-bold text-xl-start text-center">
                  $LIPO
                </h3>
                <p className="font-iner-regular font-size-18 line-height-1-2 text-xl-start text-center">
                  $LIPO unlocks a range of benefits for users, including more
                  bonuses, increased credit, higher cashback rewards, and
                  additional perks. By owning $LIPO tokens, users can take part
                  in various platform activities and earn rewards that can be
                  exchanged for real-world value. $LIPO tokens offer a valuable
                  opportunity for users to enhance their gaming and betting
                  experience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlartformPlayWithCrypto;
