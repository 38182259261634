import React, { useState } from "react";
import PlatformHero from "./PlatformHero/PlatformHero";
import PlatformToggleContent from "./PlatformToggleContent/PlatformToggleContent";
import PlatformRanking from "./PlatformRanking/PlatformRanking";
import PlatformToggleContentCards from "./PlatformToggleContent/PlatformToggleContentCards/PlatformToggleContentCards";
import { PlatformGrowFaster } from "./PlatformGrowFaster/PlatformGrowFaster";
import PlartformPlayWithCrypto from "./PlartformPlayWithCrypto/PlartformPlayWithCrypto";
import PlartformEasyPlayWithCrypto from "./PlartformEasyPlayWithCrypto/PlartformEasyPlayWithCrypto";
import PlatformCommission from "./PlatformCommission/PlatformCommission";
import PlatformHelp from "./PlatformHelp/PlatformHelp";
import Footer from "../../Components/Footer/Footer";

const PlatformPage = () => {
  const [key, setKey] = useState("");

  return (
    <div className="white-color overflow-hidden">
      <PlatformHero />
      <PlatformToggleContent tabkey={key} setKey={setKey} />
      <PlatformToggleContentCards tabKey={key} />
      <PlatformGrowFaster />
      <PlartformPlayWithCrypto />
      <PlartformEasyPlayWithCrypto />
      <PlatformCommission />
      <PlatformRanking />
      <PlatformHelp />
      <Footer />
    </div>
  );
};

export default PlatformPage;
