import React, { useRef, useState } from "react";
// import SliderRight from '../../SliderRight/SliderRight';
import Title from "../../../Components/Title/Title";
import Description from "../../../Components/Description/Description";

import "./Tokenomics.scss";
// import SliderButtons from '../../SliderRight/SliderButtons/SliderButtons';
import { blog, blogExtras } from "../../../Globals/tokenomics";
import SliderRight from "../../../Components/SliderRight/SliderRight";
import SliderButtons from "../../../Components/SliderRight/SliderButtons/SliderButtons";
import TokenomicsSlide from "./TokenomicsSlide/TokenomicsSlide";
import Tag from "../../../Components/Tags/Tag";
import TokenomicsFirst from "./TokenomicsSlide/TokenomicsFirst";
import TokenomicsLast from "./TokenomicsSlide/TokenomicsLast";

const Tokenomics = () => {
  const sliderRef = useRef(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const id = window.location.pathname.split("/")[2];
  return (
    <section
      id="tokenomics-section"
      className="section-interspace large-container mt-5 mb-5 pt-5 "
    >
      <div className="d-flex h-100 align-items-center justify-content-end w-100 container-main gap-3 flex-column text-center">
        <div className="text-center txt-container d-flex  flex-column ">
          <div className="d-flex m-auto">
            {" "}
            <Tag
              background="linear-gradient(45deg, #FF9C42, #C236FF)"
              color="#000"
              uppertitle="LIVEPOT"
            />
          </div>
          <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-0">
            Tokenomics
          </h2>
        </div>{" "}
        {/* <SliderButtons
          sliderRef={sliderRef}
          atStart={atStart}
          atEnd={atEnd}
          setAtEnd={setAtEnd}
          setAtStart={setAtStart}
        /> */}
      </div>

      <SliderRight
        sliderRef={sliderRef}
        atStart={atStart}
        atEnd={atEnd}
        setAtEnd={setAtEnd}
        setAtStart={setAtStart}
        extraClass="slider-right-tokenomics"
      >
        <TokenomicsFirst
          img={blog[0].bg}
          date={blog[0].date}
          type={blog[0].type}
          title={blog[0].title}
          subtitle={blog[0].shortDescription}
        />
        {blog.map((el, i) => {
          return (
            <React.Fragment key={i}>
              <TokenomicsSlide
                img={el.bg}
                date={el.date}
                type={el.type}
                title={el.title}
                subtitle={el.shortDescription}
              />
            </React.Fragment>
          );
        })}
        <TokenomicsLast
          img={blogExtras[0].bg}
          date={blogExtras[0].date}
          type={blogExtras[0].type}
          bottomText={blogExtras[2].bottomText}
          title={blogExtras[0].title}
          priceText={blogExtras[2].priceText}
          subtitle={blogExtras[0].shortDescription}
          color="launch"
        />
        <TokenomicsLast
          img={blogExtras[1].bg}
          date={blogExtras[1].date}
          type={blogExtras[1].type}
          title={blogExtras[1].title}
          bottomText={blogExtras[2].bottomText}
          subtitle={blogExtras[1].shortDescription}
          priceText={blogExtras[2].priceText}
          color="restart"
        />
        <TokenomicsLast
          img={blogExtras[2].bg}
          date={blogExtras[2].date}
          type={blogExtras[2].type}
          title={blogExtras[2].title}
          bottomText={blogExtras[2].bottomText}
          subtitle={blogExtras[2].shortDescription}
          priceText={blogExtras[2].priceText}
          color="airdrop"
          token={true}
        />
      </SliderRight>
    </section>
  );
};

export default Tokenomics;
