import React, { useState, useEffect } from "react";
import "./DigitalChip.scss";
import { Row } from "react-bootstrap";

// import { ReactComponent as LeftSliderImg } from "../../../Assets/left-arrow-slider.svg";
// import { ReactComponent as RightSliderImg } from "../../../Assets/right-arrow-slider.svg";
import SliderComp from "../../../Components/Slider/SliderComp";
import token from "../..//Homepage/Assets//chip.mp4";
import { toast } from "react-toastify";
import Tag from "../../../Components/Tags/Tag";
import Button from "../../../Components/Button/Button";
import OutlineButton from "../../../Components/OutlineButton/OutlineButton";
import { Navigate, useNavigate } from "react-router-dom";
import ButtonOutline from "../../../Components/ButtonOutline/ButtonOutline";

const DigitalChip = () => {
  const sliderData = [
    // {
    //   id: 0,
    //   image: <img src={SliderImg} loading="lazy" alt="develop" className="slider-image" />,
    // },
    // {
    //   id: 1,
    //   image: <img src={SliderImg2} loading="lazy" alt="develop" className="slider-image-small" />,
    // },
    // {
    //   id: 2,
    //   image: <img src={SliderImg3} loading="lazy" alt="develop" className="slider-image-small" />,
    // },
    // {
    //   id: 3,
    //   image: <img src={SliderImg4} loading="lazy" alt="develop" className="slider-image-small" />,
    // },
  ];

  const contentData = [
    {
      description:
        "Xiden blockchain introduces a new, low-energy way of crypto mining, giving users the possibility to no longer be conditioned by excessive energy consumption or highly specific equipment.  Xiden that aims to deliver a new internet concept consisting of multiple decentralized networks which will act as a single network connection of nodes governed by individual users.",
    },
    {
      description:
        "Edain, the first project developed on the Xiden blockchain, is an AI-powered knowledge-creation ecosystem, designed to “make knowledge accessible to everyone. The project's goal is to empower all humanity to cooperate in problem-solving. This is realized through the development of “knowledge applications” that analyze unimaginable amounts of data, returning results that users can use in various aspects of their lives.",
    },
    {
      description:
        "Ethernytree is a decentralized social responsibility platform that integrates blockchain technology to facilitate the fight against carbon emissions, the rehabilitation of degraded land areas due to progressive deforestation and the protection of forest ecosystems of national parks and virgin and quasi-virgin forests in Romania.",
    },
    {
      description:
        "Decentralized Studio is a platform where people can become the actual owners of movies or specific moments in movies by acquiring NFTs that deliver ownership rights. Decentralised Studio is confering the power to the fans by giving them the chance to engage with filmmakers and their favorite projects. Fans will have access to purchase movie posters, and footage, in a new experience in the form of  legendary NFTs.",
    },
  ];

  const [contentIndex, setContentIndex] = useState(0);

  const [displayData, setDisplayData] = useState(contentData[contentIndex]);

  const navigate = useNavigate();
  useEffect(() => {
    setDisplayData(contentData[contentIndex]);
  }, [contentIndex]);

  const comingSoonHandler = () => toast.info("Coming soon...");

  return (
    <section id="digita-chip-section">
      <div className="section-padding white-color">
        <div className="large-container">
          <div className="d-block d-lg-none">
            <SliderComp data={sliderData} setContentIndex={setContentIndex} />
          </div>
          <Row>
            <div className="col-md-6 col-12 order-md-1 order-2 d-flex justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center justify-content-md-start align-items-md-start develop-container">
                <Tag
                  background="linear-gradient(45deg, #FF9C42, #C236FF)"
                  color="#000"
                  uppertitle="TOKEN"
                />
                <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-0 text-md-start text-center">
                  The new<br></br>
                  Digital Chip.
                  <br></br>
                  <span className="color-gradient-lipo">$LIPO</span>
                </h2>

                <p className="font-size-24  font-iner-regular light-grey text-center text-md-start py-4 line-height-1-4 mw-1500-580">
                  {/* {displayData.description} */}
                  $LIPO token is the LivePot platform’s native token,
                  representing the integration of casino chips into the
                  blockchain system. It’s a transferable token between users,
                  providing a seamless and secure way for betting and earning
                  rewards.
                </p>
                <div className="d-flex">
                  <Button
                    title="WhitePaper"
                    height={48}
                    heightResponsive={48}
                    heightResponsive580={40}
                    width={150}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
                    hoverColor="#fff"
                    bgHoverColor="#08FF9B"
                    customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                    callback={(e) => {
                      toast.warning("Coming Soon");
                    }}
                  />
                  <ButtonOutline
                    title="Learn More"
                    height={48}
                    heightResponsive={48}
                    heightResponsive580={40}
                    width={150}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="trasnparent"
                    hoverColor="#fff"
                    bgHoverColor="#E169A0"
                    customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                    callback={(e) => navigate("/token")}
                  />
                  {/* <OutlineButton
                    title="Learn more"
                    onClick={(e) => {
                      Navigate('/token')
                    }}
                  /> */}
                </div>
                {/* <div className="d-flex btn-container">
                  <button className="join-btn">
                    <div className="inner-container font-size-24 font-pnm" onClick={comingSoonHandler}>
                      Join as member
                    </div>
                  </button>
                  <button className="enroll-btn">
                    <div className="inner-container font-size-24 font-pnm" onClick={comingSoonHandler}>
                      Enroll your project
                    </div>
                  </button>
                </div> */}
              </div>
            </div>

            <div className="col-md-6 col-12 order-md-2 order-1 pb-lg-0 pb-5 position-relative slider-col-height d- d-md-">
              <div className="pos-absolute-slider d-flex">
                <video
                  className="img-fluid mobile-video"
                  autoPlay
                  loop
                  playsInline
                  muted
                  src={token}
                ></video>
                {/* <img className="img-fluid" src={token} alt="" /> */}
              </div>
            </div>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default DigitalChip;
