import React from "react";

import { Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Tag from "../../../Components/Tags/Tag";
import Button from "../../../Components/Button/Button";
import BoltIcon from "./Assets/bolt.svg";

import "./PlatformHero.scss";

const PlatformHero = () => {
  return (
    <section id="platform-hero" className="pt-5">
      <div className="large-container mb-4">
        <Row className="mt-5">
          <div className=" col-md-6 col-12 order-md-1 order-2 d-flex justify-content-center align-items-center mb-xl-5 mb-5 mt-xl-5">
            <div className="d-flex flex-column my-md-5 justify-content-center align-items-center justify-content-md-start align-items-md-start develop-container left-side">
              <Tag
                background="linear-gradient(90deg, #ff9c42, #c236ff)"
                color="#000"
                uppertitle="LIVEPOT"
              />
              <h2 className="font-size-80 font-iner-bold mb-0 line-height-1 li text-md-start text-center">
                The new
                <br />
                <span>Playground.</span>
              </h2>

              <p className="font-size-24 font-iner-regular text-center text-md-start py-3 line-height-1-2">
                LivePot provides all the required infrastructure features for{" "}
                <br /> users to play&bet or to operate their own online
                casino-based
                <br /> games without the need for licences.
              </p>
              <div className="d-flex">
                <Button
                  title="Get $LIPO"
                  height={48}
                  heightResponsive={48}
                  heightResponsive580={40}
                  width={150}
                  color="#fff"
                  outlineColor="transparent"
                  bgColor="linear-gradient(90deg, #ff9c42 0%,  #c236ff 100%) 0% 0% no-repeat padding-box "
                  hoverColor="#fff"
                  bgHoverColor="#08FF9B"
                  customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                  callback={(e) =>    window.open('https://swap.livepot.com')}
                  icon={BoltIcon}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 col-12 order-md-2 order-1 pb-lg-0 pb-5 position-relative  ">
            <div className="d-flex">
              {/* <img className="img-fluid" src={HeroImg} alt="Hero Img" /> */}
            </div>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default PlatformHero;
