import React, { useRef, useState } from "react";
// import SliderRight from '../../SliderRight/SliderRight';
import Title from "../../../Components/Title/Title";
import Description from "../../../Components/Description/Description";

import "./BuiltForUsers.scss";
// import SliderButtons from '../../SliderRight/SliderButtons/SliderButtons';
import { blog } from "../../../Globals/globalBlog";
import SliderRight from "../../../Components/SliderRight/SliderRight";
import SliderButtons from "../../../Components/SliderRight/SliderButtons/SliderButtons";
import Tag from "../../../Components/Tags/Tag";
import BuiltForUsersSlide from "./BuiltForUsersSlide/BuiltForUsersSlide";
import { builtForUsers } from "../../../Globals/builtForUsers";

const BuiltForUsers = () => {
  const sliderRef = useRef(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const id = window.location.pathname.split("/")[2];
  return (
    <section
      id="builtforusers-section-homepage"
      className="section-interspace large-container my-5"
    >
      <div className="d-flex h-100 align-items-center justify-content-center w-100 container-main gap-3 flex-column mb-md-5 mb-4">
        <div className="text-center txt-container d-flex  flex-column ">
          <div className="d-flex m-auto">
            {" "}
            <Tag
              background="linear-gradient(45deg, #FF9C42, #C236FF)"
              color="#000"
              uppertitle="LIVEPOT"
            />
          </div>
          <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-0">
            Built for user
          </h2>
        </div>{" "}
      </div>

      <SliderRight
        sliderRef={sliderRef}
        atStart={atStart}
        atEnd={atEnd}
        setAtEnd={setAtEnd}
        setAtStart={setAtStart}
      >
        {builtForUsers
          .filter((item, i) => {
            return item.slug !== id;
          })
          .map((el, i) => {
            return (
              <React.Fragment key={i}>
                <BuiltForUsersSlide
                  id={i}
                  img={el.bg}
                  title={el.title}
                  subtitle={el.shortDescription}
                  tag={el.tags[0]}
                  slug={el.slug}
                  extraClass={el?.extraClass}
                />
              </React.Fragment>
            );
          })}
      </SliderRight>
    </section>
  );
};

export default BuiltForUsers;
