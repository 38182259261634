import React from "react";
import Tag from "../../../Components/Tags/Tag";
import "./Technology.scss";

export default function Technology() {
  return (
    <div id="technology-section" className="py-5">
      <div className="large-container ">
        <div className="d-flex h-100 align-items-center justify-content-end w-100 container-main gap-3 flex-column text-center">
          <div className="text-center txt-container d-flex  flex-column ">
            <div className="d-flex m-auto">
              {" "}
              <Tag
                background="linear-gradient(45deg, #FF9C42, #C236FF)"
                color="#000"
                uppertitle="$LIPO"
              />
            </div>
            <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-0">
            Technology
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 col-12">
            <div className="top-card left-col small-card my-4 p-lg-5 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
              <div className="font-iner-bold font-size-64 blue-color">wBTC</div>
              <div className="font-size-40 font-iner-bold white-color">
                Wrapped with BTC
              </div>
            </div>
            <div className="bottom-card left-col small-card my-4 p-lg-5 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
              <div className="font-iner-bold font-size-64 blue-color ">
                wSol
              </div>
              <div className="font-size-40 font-iner-bold white-color">
                Wrapped with SOL
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 pb-md-5 pb-4">
            <div className="large-card my-md-4 p-3 rounded-24 d-flex justify-content-end flex-column d-flex flex-column line-height-1-2 py-4">
              <div className="font-iner-bold font-size-64 blue-color">
                ERC20
              </div>
              <div className="font-size-40 font-iner-bold white-color">
                <span className="green-color-gradient">Built</span> Native <br></br> on 
                Xiden Blockchain.
              </div>
              <div className="font-size-40 font-iner-bold white-color">
              <span className="green-color-gradient">  Integrated </span> with all technologies.
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="top-card right-col small-card my-md-4 p-lg-5 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
              <div className="font-iner-bold font-size-64 blue-color">wETH</div>
              <div className="font-size-40 font-iner-bold white-color  ">
                Wrapped with ETH
              </div>
            </div>
            <div className="bottom-card right-col small-card my-4 p-lg-5 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
              <div className="font-size-40 font-iner-bold white-color line-height-1-1">
                Wrapped with More Tokens & More Blockchains.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
