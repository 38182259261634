import React, { useState } from "react";
import "./PlayGround.scss";
import { Row } from "react-bootstrap";

import games from "../..//Homepage/Assets//sah.jpg";
import game3 from "../..//Homepage/Assets//tm-remove.jpg";
import userBased from "../../Homepage/Assets/user-network.jpg";
import Tag from "../../../Components/Tags/Tag";
import Title from "../../../Components/Title/Title";
import wLicense from "../../Homepage/Assets/playground-license.jpg";

const DigitalChip = () => {
  const [current, setCurrent] = useState(0);
  const imgArray = [wLicense, games, userBased, game3];
  const [data, setData] = useState([
    {
      title: "License",
      description: `We take our users’ safety and security seriously, which is why we’ll operate under a valid license that ensures fair play and protects our users’ funds. Our license also ensures that we adhere to strict regulations regarding responsible gaming practices and data protection.    `,
      id: 1,
      current: true,
    },
    {
      title: "Infrastructure",
      description: `LivePot provides all the required infrastructure features for users to play&bet or to operate their own online casino-based games without the need for licenses.`,
      id: 2,
      current: false,
    },
    {
      title: "User Base Network",
      description: `The platform offers an opportunity for everyone desiring to join our growing user base of digital content creators, gamers, and entrepreneurs who want to earn money from home. By connecting with other platform users in our community, you can expand your network and increase your earning potential.  `,
      id: 3,
      current: false,
    },
    {
      title: "Games",
      description: `The platform will employ a variety of games that will be available for our users and will facilitate the development of user-created games that can be shared with the community for fast growth and high-earning potential.`,
      id: 4,
      current: false,
    },
  ]);

  const handleClick = (item) => {
    setCurrent(item.id - 1);
    const newData = data.map((d) => {
      if (d.id === item.id) {
        return { ...d, current: true };
      } else {
        return { ...d, current: false };
      }
    });
    setData(newData);
  };

  return (
    <section id="playground-section">
      <div className="section-padding white-color">
        <div className="large-container">
          <div className="d-block d-lg-none">
            {/* <SliderComp data={sliderData} setContentIndex={setContentIndex} /> */}
          </div>
          <Row>
            <div className="col-lg-6 col-12 order-lg-1 order-2 d-flex justify-content-center align-items-center ">
              <div className="d-flex flex-column justify-content-center align-items-center justify-content-lg-start align-items-lg-start develop-container pe-md-4 pe-0">
                <Tag
                  background="linear-gradient(45deg, #FF9C42, #C236FF)"
                  color="#000"
                  uppertitle="TOKEN"
                />
                <h2 className="font-size-80 font-iner-bold mb-5 line-height-1-1 mb-0 text-md-start text-center">
                  We give you the{" "}
                  <span className="color-gradient-lipo">Playground.</span>
                </h2>
                <div className="in-section-scroll">
                  {data.map((item, index) => (
                    <>
                      <p
                        key={index}
                        className={`${
                          item.current ? "active" : "innactive"
                        } font-size-16 font-iner-regular position-relative light-grey text-center mb-3 text-lg-start line-height-1-4 `}
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        <hr className="position-absolute top-line"></hr>
                        <div className="py-3 mt-3">
                          {" "}
                          <div className="mb-2">
                            {" "}
                            <Title size={24} title={item.title} />{" "}
                          </div>
                          {item.description}{" "}
                        </div>
                        <hr className="position-absolute"></hr>
                      </p>
                    </>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-12 d-flex order-lg-2 order-1 pb-lg-0 pb-md-5 pb-0 position-relative slider-col-height   justify-content-center align-items-center">
              <img
                className="img-fluid img mh-mob-img"
                src={imgArray[current]}
                alt=""
              />
            </div>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default DigitalChip;
