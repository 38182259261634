import React from "react";
import "./DiscoverNft.scss";
import NftCardImg from "./Assets/nft-img.png";
import { ReactComponent as ShoppingDiscount } from "./Assets/shopping-discount.svg";
import { ReactComponent as Plane } from "./Assets/plane.svg";
import { ReactComponent as Badge } from "./Assets/id-badge.svg";
import { ReactComponent as Headset } from "./Assets/headset.svg";
import { ReactComponent as Megaphone } from "./Assets/megaphone.svg";
import { ReactComponent as CashbackDiscount } from "./Assets/cashback-discount.svg";
import { ReactComponent as NftIcon } from "./Assets/nft-icon.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const BuffNftCard = () => {
  return (
    <div className="discover-nft-card">
      <div className="discover-nft-card-img">
        <img src={NftCardImg} alt="nft-img" className="img-fluid special-nft-img" />
      </div>
      <div className="discover-nft-card-discounts d-flex justify-content-around align-items-center w-100">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              id="green-tooltip"
              className="nft-section-tooltip"
              style={{
                position: "absolute",
              }}
            >
              <div className="d-flex align-items-center">
                <ShoppingDiscount className="me-2 inner-icon" />
                Discount 5%
              </div>
            </Tooltip>
          }
        >
          <div className="cursor-pointer">
            <ShoppingDiscount className="tooltip-icon" />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              id="blue-tooltip"
              className="nft-section-tooltip"
              style={{
                position: "absolute",
              }}
            >
              <div className="d-flex align-items-center">
                <Plane className="me-2 inner-icon" />
                Booster 5%
              </div>
            </Tooltip>
          }
        >
          <div className="cursor-pointer">
            <Plane className="tooltip-icon" />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              id="pink-tooltip"
              className="nft-section-tooltip"
              style={{
                position: "absolute",
              }}
            >
              <div className="d-flex align-items-center">
                <Badge className="me-2 inner-icon" />
                VIP Access
              </div>
            </Tooltip>
          }
        >
          <div className="cursor-pointer">
            <Badge className="tooltip-icon" />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              id="purple-tooltip"
              className="nft-section-tooltip"
              style={{
                position: "absolute",
              }}
            >
              <div className="d-flex align-items-center">
                <Headset className="me-2 inner-icon" />
                Custom Support
              </div>
            </Tooltip>
          }
        >
          <div className="cursor-pointer">
            <Headset className="tooltip-icon" />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              id="red-tooltip"
              className="nft-section-tooltip"
              style={{
                position: "absolute",
              }}
            >
              <div className="d-flex align-items-center">
                <Megaphone className="me-2 inner-icon" />
                Special Marketing
              </div>
            </Tooltip>
          }
        >
          <div className="cursor-pointer">
            <Megaphone className="tooltip-icon" />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              id="orange-tooltip"
              className="nft-section-tooltip"
              style={{
                position: "absolute",
              }}
            >
              <div className="d-flex align-items-center">
                <CashbackDiscount className="me-2 inner-icon" />
                Cashback 5%
              </div>
            </Tooltip>
          }
        >
          <div className="cursor-pointer">
            <CashbackDiscount className="tooltip-icon" />
          </div>
        </OverlayTrigger>
      </div>
      <div className="discover-nft-card-name d-flex w-100 justify-content-center align-items-center">
        <NftIcon className="nft-icon-card" />
        <div className="d-flex flex-column align-items-start justify-content-center discover-nft-card-name-txt">
          <p className="font-size-32 font-iner-bold mb-0 white-color">Centurion Robot</p>

          <p className="font-size-32 font-iner-regular mb-0">{`#4586`}</p>
        </div>
      </div>
    </div>
  );
};

export default BuffNftCard;
