import React, { useEffect, useState } from 'react'
import Button from '../../../Components/Button/Button'
import coin from './Assets/token_sus.png'
import { TOKEN_SOLD } from '../../../Globals/token'
import BoltIcon from '../../Platform/Assets/bolt.svg'

import { ReactComponent as RightArrow } from './Assets/arrow-right.svg'
import { ReactComponent as LeftArrow } from './Assets/arrow-left.svg'
import { blog } from '../../../Globals/tokenomics'
import { toast } from 'react-toastify'
const PHASE_INDEX = 3
export default function PresaleBox() {
  // current index of sales array
  const [current, setCurrent] = useState(PHASE_INDEX)

  const progress =
    (parseFloat(blog[current].quantity) /
      parseFloat(blog[current].shortDescription)) *
    100

  const handleNext = () => {
    if (current < blog.length - 1) {
      setCurrent(current + 1)
    } else {
      setCurrent(0)
    }
  }

  function handlePrev() {
    if (current > 0) {
      setCurrent(current - 1)
    } else {
      setCurrent(blog.length - 1)
    }
  }

  return (
    <div className='presale-box p-md-5 p-3 d-flex flex-column'>
      <div className='d-flex justify-content-between w-100 mb-3'>
        {' '}
        <div
          id='tag'
          style={{ border: '2px solid #C236FF', background: 'transparent' }}
          className='radius64 h-24 px-3 z-2 py-1 d-flex align-items-center justify-content-center font-iner-bold'
        >
          <span className='font-size-14 font-ti-b' style={{ color: 'white' }}>
            {blog[current].date1}
          </span>
        </div>
        <div
          id='tag'
          style={{ border: '2px solid #FF9C42', background: 'transparent' }}
          className='radius64 h-24 px-3 z-2 py-1 d-flex align-items-center justify-content-center font-iner-bold'
        >
          <span className='font-size-14 font-ti-b' style={{ color: 'white' }}>
            {blog[current].date2}
          </span>
        </div>
      </div>
      <div className='d-flex justify-content-between w-100'>
        <div className='font-size-32 white-color font-iner-bold'>
          {blog[current].type}
        </div>
        <div className='font-size-32 white-color font-iner-bold'>
          {blog[current].title}
        </div>
      </div>
      <div className='w-100'>
        <div className='progress-bar'>
          <div
            style={{
              width: `${progress}%`,
            }}
            className='h-100 sept-fill early-fill round-corners'
          ></div>
        </div>
      </div>
      <div className='w-100 d-flex justify-content-between mb-3'>
        <div className='box-coins font-size-16 font-iner-medium d-flex'>
          {blog[current] === blog[0] ? TOKEN_SOLD : blog[current].quantity}
          <img className='m-auto' src={coin} alt='' />
        </div>
        <div className='box-coins font-size-16 font-iner-medium d-flex'>
          {blog[current].shortDescription}
          <img className='m-auto' src={coin} alt='' />
        </div>
      </div>
      <div className='w-100 d-flex justify-content-between mt-3'>
        <div
          onClick={handlePrev}
          className='d-flex justify-content-center align-items-center gap-4 cursor-pointer'
        >
          <LeftArrow className='arrow ' />
          <div className='d-md-flex d-none font-size-12'>PREVIOUS PHASE</div>
        </div>

        <Button
          title={
            current === PHASE_INDEX
              ? 'Get $LIPO'
              : current < PHASE_INDEX
              ? 'Expired'
              : 'Coming soon'
          }
          height={48}
          heightResponsive={48}
          width={150}
          heightResponsive580={40}
          color='#fff'
          outlineColor='transparent'
          bgColor='linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box '
          hoverColor='#fff'
          bgHoverColor='#08FF9B'
          customClass='z-2 radius64 font-ri-m font-size-20  m-auto'
          icon={BoltIcon}
          callback={e => {
            window.open('https://swap.livepot.com')
          }}
        />
        <div
          onClick={handleNext}
          className='d-flex justify-content-center align-items-center gap-4 cursor-pointer'
        >
          <div style={{ width: '132px' }} className='d-md-flex d-none d-flex'>
            <span className='m-auto font-size-12'>NEXT PHASE</span>
          </div>
          <RightArrow className='arrow ' />
        </div>
      </div>
    </div>
  )
}
