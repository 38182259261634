import React from "react";
// import img from "./Assets/22.png";
import img from "./Assets/sah-hero-project.png";

import img2 from "./Assets/cut2.png";

import HeroComponent from "./HeroComponent/HeroComponent";
import TokenManagement from "./TokenManagement/TokenManagement";
import UsersCreateBrand from "./UsersCreateBrand/UsersCreateBrand";
import FirstTokenDeveloped from "./FirstTokenDeveloped/FirstTokenDeveloped";
import UsersCreateValue from "./UsersCreateValue/UsersCreateValue";
import Roadmap from "./Roadmap/Roadmap";
import Footer from "../../Components/Footer/Footer";
import PlatformHelp from "../Platform/PlatformHelp/PlatformHelp";

export default function ProjectPage() {
  return (
    <>
      <HeroComponent img={img} />
      <TokenManagement />
      <Roadmap />
      <UsersCreateValue />
      <UsersCreateBrand />
      <FirstTokenDeveloped img={img2} />
      <PlatformHelp />
      <Footer />
    </>
  );
}
