import coin from "./assets/coins.png";
import plug from "./assets/plug.png";
import dealer from "./assets/dealer.png";
import gameAquisition from "./assets/gameAquisition.png";
import staking from "./assets/staking.png";

export const builtFor = [
  {
    bg: coin,
    title: <>Game changers </>,
    shortDescription: `$LIPO is the value representation of a chip that will be used to bet on the LivePot platform.`,
  },
  {
    bg: plug,
    title: <>Plug&Play Integration </>,
    shortDescription: `The technology behind $LIPO is transferable and can be integrated into other casino platforms or even social platforms such as TikTok..`,
  },

  {
    bg: dealer,
    title: <>Physical Casinos </>,
    shortDescription: `In the near future, LIPO can also be used as credit in physical casinos as it is verifiable and cannot be duplicated.`,
  },
  {
    bg: gameAquisition,
    title: <>Game Acquisition </>,
    shortDescription: `$LIPO can be used to acquire games developed by other developers on the platform.`,
  },
  {
    bg: staking,
    title: <>In-platform Staking </>,
    shortDescription: `An innovative feature is that the users can stake LIPO on the platform to earn rewards from LivePot gameplay.`,
  },
];
