import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./DiscoverNft.scss";
import { ReactComponent as BuffsBtnIcon } from "./Assets/buffs-nfts-btn-icon.svg";
import { ReactComponent as SpecialBtnIcon } from "./Assets/special-nfts-btn-icon.svg";
import BuffNftCard from "./BuffNftCard";
import SpecialNftCard from "./SpecialNFTCard";
import Fade from "react-reveal/Fade";
import { Reveal } from "react-reveal";

// buttons IDs
const buffNFT = "buffNFT",
  specialNFT = "specialNFT";

const defaultButtonsState = { buffNFT: false, specialNFT: false };

const buffNFTContent = {
  imageCard: (
    <Fade>
      <BuffNftCard />
    </Fade>
  ),
  paragraph: (
    <Fade>
      <p className="font-size-24 discover-nft-txt-par font-iner-regular light-grey text-left py-4 line-height-1-4">
        NFTs with special features and advantages can offer discounts, special prices, rewards, and prizes for our
        platform's members.
      </p>
    </Fade>
  ),
};
const specialNFTContent = {
  imageCard: (
    <Fade>
      <SpecialNftCard />
    </Fade>
  ),
  paragraph: (
    <Fade>
      <p className="font-size-24 discover-nft-txt-par font-iner-regular light-grey text-left py-4 line-height-1-4">
        Outstanding NFTs are part of special categories with unique benefits and high value within the platform and on
        the open market.
      </p>
    </Fade>
  ),
};

const DiscoverNft = () => {
  const [activeButton, setActiveButton] = useState({ buffNFT: true, specialNFT: false });
  const [activeContent, setActiveContent] = useState(buffNFTContent);

  const activeButtonHandler = buttonId => {
    setActiveButton({ ...defaultButtonsState, [buttonId]: true });

    switch (buttonId) {
      case buffNFT:
        setActiveContent(buffNFTContent);
        break;
      case specialNFT:
        setActiveContent(specialNFTContent);
        break;

      default:
        setActiveContent(buffNFTContent);
    }
  };

  return (
    <div className="discover-nft-section section-padding white-color">
      <div className="large-container">
        <Row>
          <Col xl={6} className="d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-start align-items-xl-start align-items-center performance-container">
              <Reveal offset="400px" from="top" canPlay={true} direction={"bottom"}>
                <h2 className="font-size-56 discover-nft-txt font-iner-bold">
                  Discover <span className="gradient-txt">NFT </span>Collections
                </h2>
              </Reveal>
              <Reveal from="top" canPlay={true} direction={"top"}>
                <h4 className="font-size-32 discover-nft-txt font-pnm">
                  Real Market value. Transferable. Buff advantages.
                </h4>
              </Reveal>

              {activeContent.paragraph}

              <div className="w-100 d-flex flex-column">
                <div className="d-flex zixxar-buttons-container zixxar-buttons-container-desktop justify-content-center align-items-center w-100 order-2 order-sm-2 order-md-2 order-lg-2 order-xl-1">
                  <button
                    className={`nft-btn buff-nft-button zixxar-reverse-gradient`}
                    onClick={() => activeButtonHandler(buffNFT)}
                  >
                    <div className={`inner-btn font-size-24 font-pnm ${activeButton.buffNFT ? "active" : "inactive"}`}>
                      <BuffsBtnIcon className="me-2 nft-icons" /> Buffs Nft's
                    </div>
                  </button>

                  <button
                    className="nft-btn special-nft-button zixxar-reverse-gradient"
                    onClick={() => activeButtonHandler(specialNFT)}
                  >
                    <div
                      className={`inner-btn font-size-24 font-pnm ${activeButton.specialNFT ? "active" : "inactive"}`}
                    >
                      <SpecialBtnIcon className="me-2 nft-icons" />
                      Special Nft's
                    </div>
                  </button>
                </div>

                <div className="more-info-card order-1 order-sm-1 order-md-1 order-lg-1 order-xl-2">
                  <p className="font-size-20 font-iner-regular light-grey text-left line-height-1-5 mb-0">
                    Each NFT can be unlocked by achieving ranks, when obtaining badges, or by purchasing mystery boxes.
                    The NFTs have multiple benefits that offer the chance for the ZIXXar members to earn more rewards,
                    acquire VIP status within the community, and many more.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={6} className="d-flex discover-nft-card-col justify-content-center align-items-center">
            {activeContent.imageCard}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DiscoverNft;
