import React, { useState, useEffect } from "react";
import "./TokenDistribution.scss";
import { Row } from "react-bootstrap";

import { toast } from "react-toastify";
import Tag from "../../../Components/Tags/Tag";
import Button from "../../../Components/Button/Button";
import tokenManagemet from "../Assets/tokenomics1.png";
import coin from "../Assets/token_sus-coin.png";
import { ReactComponent as RightArrow } from "../Assets/right-arrow.svg";
import { ReactComponent as LeftArrow } from "../Assets/left-arrow.svg";
import largeToken from "../Assets/large-token.png";
import { TOKEN_SOLD, TOKEN_TOTAL } from "../../../Globals/token";
import slidesGlobal from "../../../Globals/slides";
const TokenDistribution = () => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const [slides, setSlides] = useState(slidesGlobal);

  const handleNext = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    } else {
      setCurrentSlide(slides.length - 1);
    }
  };

  return (
    <section id="TokenDistribution-section">
      <div className="section-padding white-color">
        <div className="large-container">
          <div className="d-block d-lg-none"></div>
          <Row>
            <div className="col-md-6 col-12 order-md-1 order-2 d-flex justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center justify-content-md-start align-items-md-start develop-container">
                <Tag
                  background="linear-gradient(45deg, #FF9C42, #C236FF)"
                  color="#000"
                  uppertitle="TOKEN"
                />
                <h2 className="font-size-56 font-iner-bold line-height-1-1 mb-3 text-md-start text-center">
                  Token Management
                </h2>
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <div className="font-size-24 font-iner-light gray-color">
                      {" "}
                      Total $LIPO:
                    </div>
                    <div className="font-size-24 font-iner-light gray-color">
                      {" "}
                      Distributed $LIPO:
                    </div>
                  </div>

                  <div className="ms-4">
                    <div className="font-iner-bold  font-size-24">
                      100.000.000 <img src={coin} alt="" />{" "}
                    </div>
                    <div className="font-iner-bold font-size-24">
                    8.697.653
                      <img src={coin} alt="" />
                    </div>
                  </div>
                </div>

                <div className="arrow-container my-4">
                  <LeftArrow
                    onClick={handlePrevious}
                    className="me-2 cursor-pointer"
                  />
                  <RightArrow
                    onClick={handleNext}
                    className="ms-2 cursor-pointer"
                  />
                </div>
                <div className="tag-container d-flex mb-2">
                  <div className="me-2">
                    {" "}
                    {slides[currentSlide].status === "In Progress" ? (
                      <Tag
                        background="linear-gradient(45deg, #84CC16, #86EFAC)"
                        color="#000"
                        uppertitle={slides[currentSlide].status}
                      />
                    ) : (
                      <Tag
                        background="linear-gradient(45deg, #FDE047, #FDE68A)"
                        color="#000"
                        uppertitle={slides[currentSlide].status}
                      />
                    )}
                  </div>
                  <div className="ms-2">
                    {" "}
                    <Tag
                      background="linear-gradient(45deg, #FF9C42, #C236FF)"
                      color="#000"
                      uppertitle={slides[currentSlide].date}
                    />
                  </div>{" "}
                </div>

                <div className="boxes-container mb-4 d-flex">
                  <div className="first-box-token-distributions me-3">
                    <div className="purple-color font-size-32 font-iner-bold">
                      {(Number(slides[currentSlide].coins.replaceAll(".", "")) /
                        TOKEN_TOTAL) *
                        100}
                      %
                    </div>
                    <div className="font-iner-bold font-size-18">
                      {slides[currentSlide].title}
                    </div>
                    <div className="font-size-32 font-iner-bold">
                      {slides[currentSlide].coins}{" "}
                      <img src={largeToken} alt="" />
                    </div>
                  </div>
                  {slides[currentSlide + 1] && (
                    <div className="second-box-token-distributions ms-3">
                      <div className="purple-color font-size-24 font-iner-bold">
                        {(Number(
                          slides[currentSlide + 1].coins.replaceAll(".", "")
                        ) /
                          TOKEN_TOTAL) *
                          100}
                        %
                      </div>
                      <div className="font-iner-bold font-size-13">
                        {slides[currentSlide + 1].title}
                      </div>
                      <div className="font-size-24 font-iner-bold">
                        {slides[currentSlide + 1].coins}{" "}
                        <img src={largeToken} alt="" />
                      </div>
                    </div>
                  )}
                </div>

                <div className="text-container">
                  <div className="font-size-iner-regular font-size-16 gray-color">
                    To reduce the Total supply and increase the $LIPO’s value,
                    any unsold tokens from the presale period will be burned. To
                    reduce the Total supply and increase the $LIPO’s value, any
                    unsold tokens from the presale period will be burned.
                  </div>
                </div>
                <div className="w-100 ">
                  <div className="progress-bar">
                    <div
                      className="h-100 sept-fill early-fill round-corners"
                      style={{ width: slides[currentSlide].widthBar }}
                    ></div>
                  </div>
                  <div className="w-100 d-flex justify-content-between mb-3">
                    <div className="box-coins font-size-16 font-iner-medium">
                      {slides[currentSlide].sold} <img src={coin} alt="" />
                    </div>
                    <div className="box-coins font-size-16 font-iner-medium">
                      {slides[currentSlide].coins}
                      <img src={coin} alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    title="Get $LIPO"
                    height={48}
                    heightResponsive={48}
                    width={150}
                    heightResponsive580={40}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
                    hoverColor="#fff"
                    bgHoverColor="#08FF9B"
                    customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                    callback={(e) => {
                      //open new link in new tab
                      window.open('https://swap.livepot.com')
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12 order-md-2 order-1 pb-lg-0 pb-5 position-relative slider-col-height d-flex justify-content-center align-items-center">
              <img className="img-fluid" src={tokenManagemet} alt="" />
            </div>
          </Row>
        </div>
      </div>
    </section>
  );
};
export default TokenDistribution;
