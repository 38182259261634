import React from "react";
import Tag from "../../../Components/Tags/Tag";
import pad from '../Assets/pad.png'
import slot from '../Assets/slot.png'
import stake from '../Assets/stake.png'
import trade from '../Assets/trade.png'
import './UseLipo.scss'
export default function UseLipo() {
  return (
    <div id="use-lipo-section" className="py-5">
      <div className="large-container">
      <div className="text-center txt-container d-flex flex-column ">
        <div className="d-flex m-auto">
          {" "}
          <Tag
            background="linear-gradient(45deg, #FF9C42, #C236FF)"
            color="#000"
            uppertitle="LIVEPOT"
          />
        </div>
        <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-0">
          Use $LIPO
        </h2>
      </div>
      <div className="row my-5 px-5">
        <div className="col-md-6 order-md-1 order-2">
            <div className="d-flex justify-content-center  h-100 flex-column ">
          <div className="font-size-32 color-gradient-lipo font-iner-bold">
          Bet & Win
          </div>
          <div className="font-size-18 font-iner-regular gray-color mw-md-460">
          Experience the thrill of betting with cryptocurrency on LivePot’s cutting-edge Web3 technology platform. With our wide range of sports betting and casino games, you can enjoy competitive odds and win big.
          </div></div>
        </div>
        <div className="col-md-6 order-md-2 order-1 d-flex">
            <img  className="img-fluid mb-3" src={slot} alt="" />
        </div>
      </div>
      <div className="row py-4 px-5">
      <div className="col-md-6 order-md-1 order-11 d-flex">
            <img className="img-fluid mb-3" src={pad} alt="" />
        </div>
        <div className="col-md-6 order-md-2 order-1">
            <div className="d-flex justify-content-center  h-100 flex-column ">
          <div className="font-size-32 color-gradient-lipo font-iner-bold">
     Play & Earn
          </div>
          <div className="font-size-18 font-iner-regular gray-color mw-md-460">
          Play entertaining games on LivePot and earn cryptocurrency rewards as you go. Our platform provides a virtual playground where you can create your own games or join others to accumulate rewards and grow your earnings.
          </div></div>
        </div>
     
      </div>
      <div className="row py-4 px-5">
        <div className="col-md-6 order-md-1 order-2">
            <div className="d-flex justify-content-center  h-100 flex-column ">
          <div className="font-size-32 color-gradient-lipo font-iner-bold">
            Stake & Earn
          </div>
          <div className="font-size-18 font-iner-regular gray-color mw-md-460">
          Stake your cryptocurrency on LivePot and earn rewards for your loyalty and gameplay. Our platform employs a ranking system that rewards users with increased credit limits, deposit bonuses, and exciting rewards based on their wagered and deposited amounts.
          </div></div>
        </div>
        <div className="col-md-6 order-md-2 order-1 d-flex">
            <img className="img-fluid mb-3" src={stake} alt="" />
        </div>
      </div>
      <div className="row py-4 px-5">
      <div className="col-md-6 rder-md-2 order-1 d-flex">
            <img className="img-fluid mb-3" src={trade} alt="" />
        </div>
        <div className="col-md-6 rder-md-2 order-1">
            <div className="d-flex justify-content-center  h-100 flex-column ">
          <div className="font-size-32 color-gradient-lipo font-iner-bold">
Trade & Earn
          </div>
          <div className="font-size-18 font-iner-regular gray-color mw-md-460">
          Come together with our rapidly growing community and compete for the chance to win lucrative cryptocurrency rewards. LivePot provides various opportunities for users to engage with others while aiming for the top spot and earning the title of the best competitor.
          </div></div>
        </div>
     
      </div>
    </div>
    </div>
  );
}
