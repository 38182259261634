import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';


const PageTracking = () => {
    const location = useLocation();
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
            ReactGA.initialize('G-W1N7C9L4HB');
        }
        setInit(true);
    }, []);

    useEffect(() => {
        if (init) {
            ReactGA.send("pageview");
        }
    }, [init, location]);
};

export default PageTracking;
