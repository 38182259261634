import React from "react";
import Tag from "../../../Components/Tags/Tag";
import "./UsersCreateBrand.scss";
import money from "../Assets/money.png";
import wispr from "../Assets/wispr-icon.png";

export default function UsersCreateBrand() {
  return (
    <div id="UsersCreateBrand-section" className="py-5">
      <div className="large-container ">
        <div className="d-flex h-100 align-items-center justify-content-end w-100 container-main gap-3 flex-column text-center">
          <div className="text-center txt-container d-flex  flex-column ">
            <div className="d-flex m-auto">
              {" "}
              <Tag
                background="linear-gradient(45deg, #FF9C42, #C236FF)"
                color="#000"
                uppertitle="MARKETING STRATEGY"
              />
            </div>
            <h2 className="font-size-80 font-iner-bold white-color line-height-1-1 mb-0">
            Users create the Brand.
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 col-12">
            <div className="top-card left-col small-card my-md-4  my-3 p-lg-4 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
              <div className="font-iner-bold font-size-40 yellow-color line-height-1">
                Creating Visibility & Brand Awareness{" "}
              </div>
              <div className="font-iner-bold font-size-40 white-color line-height-1">
                with Players.
              </div>
            </div>
            <div className="bottom-card grey-background left-col small-card my-md-4  my-3 p-lg-4 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
              <div className="font-iner-bold font-size-64 blue-color "></div>
              <div className="font-size-40 font-iner-bold white-color line-height-1">
                <div>
                  <img className="img-fluid mb-3" src={money} alt="" />
                </div>
                More liquidity will involve more traders.
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 pb-md-5  pb-3">
            <div className="large-card my-md-4 p-3 rounded-24 d-flex justify-content-end flex-column d-flex flex-column line-height-1-2 py-4">
              <div className="font-size-40 font-iner-bold white-color">
                Global Visibility with <br></br> F1 and MotoGP.
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="top-card grey-background right-col small-card my-md-4 p-lg-4 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
              <div className="font-size-40 font-iner-bold white-color line-height-1-1 ">
                <span className="color-gradient-lipo">$LIPO</span> integrated
                into other platforms.
              </div>
            </div>
            <div className="bottom-card position-relative right-col small-card my-md-4  my-3 p-lg-4 p-md-3 p-4 rounded-24 d-flex flex-column justify-content-center">
              <div className="logo-wispr d-flex">
                <img src={wispr} alt="" />
              </div>
              <div className="font-size-40 font-iner-bold white-color line-height-1-1">
                <span className="color-gradient-lipo">
                  {" "}
                  More<br></br>
                  benefits for<br></br>
                </span>
                WispR users.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
