import React, { useState, useEffect } from "react";
import "./FirstTokenDeveloped.scss";
import { Row } from "react-bootstrap";
import { toast } from "react-toastify";

import Tag from "../../../Components/Tags/Tag";
import ButtonOutline from "../../../Components/ButtonOutline/ButtonOutline";
import {  useNavigate } from "react-router-dom";
import video from './Assets/cdt-video.mp4'

const FirstTokenDeveloped = ({ img }) => {
  const navigate=useNavigate()
  return (
    <section id="FirstTokenDeveloped-hero" className=" py-5">

        <div className="large-container ">
          <Row className="">
            <div className=" col-md-6 col-12 order-md-1 order-2 d-flex justify-content-center align-items-center mb-xl-5 mb-5 mt-md-5">
              <div className="d-flex flex-column mb-md-5 justify-content-center align-items-center justify-content-md-start align-items-md-start develop-container">
                <Tag
                  background="linear-gradient(45deg, #FF9C42, #C236FF)"
                  color="#000"
                  uppertitle="DEVELOPED BY"
                />
                <h2 className="font-size-80 white-color font-iner-bold line-height-1 mb-0 li text-md-start text-center">
                  First Crypto Token Developed & Owned by CryptoDATA Co.
                </h2>

                <p className="font-size-24 font-iner-regular light-grey text-center text-md-start py-3 line-height-1-2">
                  {/* {displayData.description} */}
                  $LIPO, LivePot's native token, has immense utility in both the virtual and real worlds. As a digital chip, it utilizes blockchain technology for traceability and transparency, with a minimum value and potential for appreciation over time. Its versatility enables easy integration into different platforms and serves as a unit of measure in the LivePot ecosystem.

                </p>
                <div className="d-flex">
                  <ButtonOutline
                    title="Read More"
                    height={48}
                    heightResponsive={48}
                    heightResponsive580={40}
                    width={150}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="trasnparent"
                    hoverColor="#fff"
                    bgHoverColor="#E169A0"
                    customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                    callback={(e) => navigate('/token')}
        
                  />
        
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12 order-md-2 order-1 pb-lg-0 pb-5 position-relative d-md-none d-block ">
              <div className="d-flex justify-content-center">
                <img className="img-fluid" src={img} alt="" />
              </div>
            </div>
            <video src={video} playsInline muted loop autoPlay className="position-absolute video-abs d-none d-md-block"></video>
          </Row>
        </div>

    </section>
  );
};

export default FirstTokenDeveloped;
