import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './BuiltForUsersSlide.scss';


const MAX_CHARS = 50;
const MAX_CHARS_TITLE = 100;

const BuiltForUsersSlide= ({ ...props }) => {
  return (
    <div

      state={{rerender:true}}
      className={`slide ${props.extraClass} radius16 overflow-hidden h-100 user-select-auto text-decoration-none `}
      id="builtfor-users-slide"
    >
      <div className='d-flex'> 
      <img
        // style={{ minHeight: '60%' }}
        src={props.img}
        alt="news img"
        className="img-fluid img-blog m-auto"
      /></div>
      <div className="d-flex px-4 position-relative main-content-container align-items-start justify-content-start  flex-column ">
        <div className="d-flex w-100  align-items-center justify-content-between gap-2">

        </div>
        <span className={`font-size-24 font-iner-bold white-color lh-normal mb-1`}>
          {/* {props.title.substring(0, MAX_CHARS_TITLE)}
          {props.title.length > MAX_CHARS_TITLE ? '...' : ''} */}
          {props.title}
        </span>
        <span className="font-size-16 font-iner-regular gray-color line-height-1-3 ">
     {props.subtitle}
        </span>
      </div>
    </div>
  );
};

export default BuiltForUsersSlide;
