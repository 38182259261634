import React from "react";
import "./Homepage.scss";
import Hero from "./HomepageHero/HomepageHero";
import SuccessBusiness from "./SuccessBusiness/SuccessBusiness";
import Performance from "./Performance/Performance";
import Incentive from "./Incentive/Incentive";
import DiscoverNft from "./DiscoverNft/DiscoverNft";
import RankingSystem from "./RankingSystem/RankingSystem";
import UnlockMedals from "./UnlockMedals/UnlockMedals";
import MoreThanSales from "./MoreThanSales/MoreThanSales";
import MakeADifference from "./MakeADifference/MakeADifference";
import Register from "../../Components/Register/Register";
import Footer from "../../Components/Footer/Footer";
import Networking from "./Networking/Networking";
import NFTSection from "./NFTSection/NFTSection";
import SecretClub from "./SecretClub/SecretClub";
import JoinBanner from "./JoinBanner/JoinBanner";
import DigitalChip from "./DigitalChip/DigitalChip";
import FirstProject from "./FirstProject/FirstProject";
import PlayWinEarn from "./PlayWinEarn/PlayWinEarn";
import Tokenomics from "./Tokenomics/Tokenomics";
import PlayGround from "./PlayGround/PlayGround";
import PerfectStrategy from "./PerfectStrategy/PerfectStrategy";
import BuiltForUsers from "./BuiltForUsers/BuiltForUsers";
import PlatformHelp from "../Platform/PlatformHelp/PlatformHelp";

const Homepage = () => {
  return (
    <div className="homepage white-color s">
      {/* <Hero /> */}
      {/* <JoinBanner /> */}
      {/* <SecretClub/> */}
      <Hero />
      <FirstProject/>
      <DigitalChip />
      <PlayWinEarn/>
      <PerfectStrategy/>
      <Tokenomics />
      <PlayGround />
      <BuiltForUsers/>
      {/* <SuccessBusiness /> */}

      {/* <LatestNews /> */}


      {/* <Performance />
      <Incentive />
      <RankingSystem />
      <NFTSection />
      <DiscoverNft />
      <UnlockMedals />
  MoreThanSales /> */}
      {/* <Networking />
      <MakeADifference />
      <Register /> */}
            <PlatformHelp />
      <Footer />
    </div>
  );
};

export default Homepage;
