import React, { useState, useEffect } from "react";
import "./HomepageHero.scss";
import { Row } from "react-bootstrap";
import BoltIcon from "../../Platform/Assets/bolt.svg";

// import { ReactComponent as LeftSliderImg } from "../../../Assets/left-arrow-slider.svg";
// import { ReactComponent as RightSliderImg } from "../../../Assets/right-arrow-slider.svg";
import HeroLeft from "../Assets/img.png";
import { toast } from "react-toastify";
import Tag from "../../../Components/Tags/Tag";
import Button from "../../../Components/Button/Button";

// video assets
import token from "../../Homepage/Assets/chip.mp4";

const TokenHero = () => {
  return (
    <section id="tokenomics-hero" className="pt-5">
      <div className="large-container mb-4 h-100 ">
        <Row className="h-100 ">
          <div className=" col-md-6 col-12 order-md-1 order-2 d-flex justify-content-center align-items-center mb-xl-5 mb-5 mt-md-5">
            <div className="d-flex flex-column mb-md-5 justify-content-center align-items-center justify-content-md-start align-items-md-start develop-container">
              <Tag
                background="linear-gradient(45deg, #FF9C42, #C236FF)"
                color="#000"
                uppertitle="Gaming Platform"
              />
              <h2 className="font-size-80 font-iner-bold line-height-1 mb-0 li text-md-start text-center">
                The new
                <br></br>
                Digital Chip
              </h2>

              <p className="font-size-24 mw-1500-580 font-iner-regular light-grey text-center text-md-start py-3 line-height-1-2">
                {/* {displayData.description} */}
                $LIPO token is the LivePot platform’s native token, representing
                the integration of casino chips into the blockchain system. It’s
                a transferable token between users, providing a seamless and
                secure way for betting and earning rewards.
              </p>
              <div className="d-flex">
                <Button
                  title="WhitePaper"
                  height={48}
                  heightResponsive={48}
                  heightResponsive580={40}
                  width={150}
                  color="#fff"
                  outlineColor="transparent"
                  bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
                  hoverColor="#fff"
                  bgHoverColor="#08FF9B"
                  customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
                  callback={(e) => {
                    window.open(
                      "https://livepot.s3.eu-central-1.amazonaws.com/Livepot/Whitepaper/LivePotWhitepaper.pdf",
                      "_blank"
                    );
                  }}
                />
              </div>

              {/* <div className="d-flex btn-container">
                  <button className="join-btn">
                    <div className="inner-container font-size-24 font-pnm" onClick={comingSoonHandler}>
                      Join as member
                    </div>
                  </button>
                  <button className="enroll-btn">
                    <div className="inner-container font-size-24 font-pnm" onClick={comingSoonHandler}>
                      Enroll your project
                    </div>
                  </button>
                </div> */}
            </div>
          </div>

          <div className="col-md-6 d-flex  col-12 order-md-2 order-1 pb-lg-0 pb-5 position-relative h-100 ">
            <div className="d-flex m-auto justify-content-center align-items-center ">
              {/* <img className="img-fluid " src={HeroLeft} alt="" /> */}
              <video
                className="img-fluid mobile-video"
                autoPlay
                loop
                playsInline
                muted
                src={token}
              ></video>
            </div>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default TokenHero;
