import { TOKEN_SOLD } from "./token";
const slides = [
  {
    title: "Private Sale",
    percentage: "50%",
    coins: "15.000.000",
    sold: TOKEN_SOLD,
    date: "May 2023",
    status: "Ended",
    widthBar: "53%",
  },
  {
    title: "Public Sale Phase 1",
    percentage: "50%",
    coins: "25.000.000",
    date: " July 2023",
    status: "In Progress",
    sold: 3.653,
    widthBar: "0%",
  },
  {
    title: "Public Sale Phase 2",
    percentage: "50%",
    coins: "20.000.000",
    date: "September 2023",
    status: "Not Started",
    sold: 0,
    widthBar: "0%",
  },
  {
    title: "Public Sale Phase 3",
    percentage: "50%",
    coins: "15.000.000",
    date: "November 2023",
    status: "Not Started",
    sold: 0,
    widthBar: "0%",
  },
  {
    title: "Public Sale Phase 4",
    percentage: "50%",
    coins: "10.000.000",
    date: "January 2024",

    status: "Not Started",
    sold: 0,
    widthBar: "0%",
  },
  {
    title: "Public Sale Phase 5",
    percentage: "50%",
    coins: "10.000.000",
    date: "March 2024",
    status: "Not Started",
    sold: 0,
    widthBar: "0%",
  },
];

export default slides;
