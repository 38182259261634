import React from "react";

import "./PlartformEasyPlayWithCrypto.scss";
import MasterCardImg from "./Assets/mastercard-pay.png";
import ApplePayImg from "./Assets/apple-pay.png";
import GooglePayImg from "./Assets/google-play.png";

export function PlartformEasyPlayWithCrypto() {
  return (
    <div id="platform-easy-play-with-crypto-section">
      <div className="large-container d-flex flex-column justify-content-center">
        <div className="text__container row">
          <div className="col-md-5 col-12 d-flex flex-column justify-content-center align-center">
            <h2 className="font-size-54 font-iner-bold line-height-1-1">
              Easy to
              <span className="color-gradient-lipo ">Play with Crypto.</span>
            </h2>
          </div>

          <div className="col-md-7 col-12">
            <div className="row">
              <div className="cards__container col-sm-4 col-12">
                <div className="cards__container--wrapper">
                  <div className="cards__container--label">
                    <img
                      className="img-fluid"
                      src={MasterCardImg}
                      alt="MasterCardImg"
                    />
                  </div>
                  <h3 className="font-size-22 font-iner-bold line-height-1-1">
                    Credit Card
                  </h3>
                </div>
              </div>
              <div className="cards__container col-sm-4 col-12">
                <div className="cards__container--wrapper">
                  <div className="cards__container--label">
                    <img
                      className="img-fluid"
                      src={ApplePayImg}
                      alt="ApplePayImg"
                    />
                  </div>
                  <h3 className="font-size-22 font-iner-bold line-height-1-1">
                    Apple Pay
                  </h3>
                </div>
              </div>
              <div className="cards__container col-sm-4 col-12">
                <div className="cards__container--wrapper">
                  <div className="cards__container--label">
                    <img
                      className="img-fluid"
                      src={GooglePayImg}
                      alt="GooglePayImg"
                    />
                  </div>
                  <h3 className="font-size-22 font-iner-bold line-height-1-1">
                    Google Pay
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlartformEasyPlayWithCrypto;
