import { routes } from "./routes";

export const navbarRoutes = [
{
    title : "Project",
    route : [routes.project],
    submenu : false

},
    {
        title: "Token",
        route: [routes.token],
        submenu: false

    },

    {
        title: "Platform",
        route: [routes.platform],
        submenu: false
    }

];
