import './GamingPage.scss'
// import token from "./assets/gaming-page-video.mp4";
import joystick from './assets/livepot-gaming-joystick-og.png'
import Button from '../../Components/Button/Button'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useState } from 'react'
import { FaFacebook } from 'react-icons/fa'
import { AiOutlineTwitter } from 'react-icons/ai'
import { AiOutlineInstagram } from 'react-icons/ai'
import { AiFillYoutube } from 'react-icons/ai'
import { IoPaperPlaneOutline } from 'react-icons/io5'
import { FaTiktok } from 'react-icons/fa'
import { FaDiscord } from 'react-icons/fa'

import token from '../Homepage/Tokenomics/Assets/token_sus.png'
import HoursCountdown from '../../Components/HoursCountdown/HoursCountdown'

const GamingPage = () => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [visibleAfterCountdown, setVisibleAfterCountdown] = useState(true)
  const getRemainingHours = (visible) => {
    setVisibleAfterCountdown(false)
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await axios.post('https://subscribe.livepot.com/subscribers/subscribeToNewsletter', {
        email: email,
      })
      if (response.status === 200) {
        toast.success('Thank you for subscribing to our newsletter')
        setSuccess(true)
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          toast.warning('Please enter a valid email address!')
          break
        case 409:
          toast.warning('Email already subscribed!')
          break
        default:
          toast.warning('Oups, something went wrong!')
      }
    }
  }

  return (
    <div className='gaming-page'>
      <div className='gaming-page-overlay'></div>
      <div className='large-container gaming-page-row d-flex justify-content-center flex-column'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-md-6'>
            <div className='d-flex flex-column w-100 gap-3  px-lg-5 px-3 col-left-title-container'>
              {/* <video
                className="img-fluid mobile-video-token "
                autoPlay
                loop
                playsInline
                muted
                src={token}
              ></video> */}
              <img src={token} alt='' className='img-fluid' width={'160px'} height={'160px'} />

              <div>
                <span className='font-size-80 font-iner-bold lh-1 text-light color-gradient-lipo mb-0 align-self-start'>Beta Version Launch</span>
                <br />
              </div>

              <div className='font-size-16 color-light-grey font-iner-regular gaming-page-descaription'>
                <span className='text-light'>The official Beta Version Launch</span> of the LivePot online gaming platform is happening!{' '}
                <span className='text-white'>
                  <br /> Join us
                </span>{' '}
                mega online launch party full of fun and surprises.
              </div>
              {/* <div className="countdown-gradient d-block d-lg-none">
                <GradientCountdown
                  date="2023-08-18 04:00"
                  size={90}
                    trailColor={"rgba(255,255,255,0.9)"}
                />
              </div> */}

              <div className='hours-countdown d-block d-lg-none'>
                <div className='stay-tuned-wrapper  font-size-16 font-iner-regular'>STAY TUNED</div>

                {/* <HoursCountdown date={new Date('2024-07-04T17:00:00')} remainingHours={getRemainingHours} />/ */}
              </div>
              {!success ? (
                <div className='position-relative w-100 mt-4'>
                  <form onSubmit={handleSubmit}>
                    <input placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)} type='text' className='input-rnf input-subscribe' />
                    <Button
                      title='Subscribe'
                      height={48}
                      heightResponsive={48}
                      heightResponsive580={40}
                      width={100}
                      color='#fff'
                      outlineColor='transparent'
                      bgColor='linear-gradient(90deg, #FF9C42 0%,  #C236FF 100%) 0% 0% no-repeat padding-box '
                      hoverColor='#fff'
                      bgHoverColor='#08FF9B'
                      customClass='z-2 radius64 font-ri-m font-size-20 position-absolute button-center'
                      callback={(e) => handleSubmit(e)}
                    />
                  </form>
                </div>
              ) : null}
            </div>
          </div>
          <div className='col-md-6'>
            <img src={joystick} className='img-fluid' />
          </div>
        </div>
        <div className='row mt-sm-5 flex-md-row flex-column-reverse mb-md-0 mb-4 align-items-center'>
          <div className='col-md-6'>
            <div
              className='d-flex gaming-icon-group align-items-center justify-content-center py-2 gap-3 mx-xxl-0 mx-lg-5 mx-md-3 mx-auto px-3
            '
            >
              <a href='https://www.facebook.com/people/LivePot/100091509611728/?is_tour_dismissed=true' target='_blank'>
                <FaFacebook size={'24px'} />
              </a>
              <a href='https://www.instagram.com/livepotofficial/' target='_blank'>
                <AiOutlineInstagram size={'24px'} />
              </a>
              <a href='https://www.tiktok.com/@livepotofficial' target='_blank'>
                <FaTiktok size={'24px'} />
              </a>
              <a href='https://discord.com/invite/Eh34UEw7DU' target='_blank'>
                <FaDiscord size={'24px'} />
              </a>
              <a href='https://twitter.com/LivePotOfficial' target='_blank'>
                <AiOutlineTwitter size={'24px'} />
              </a>
              <a href='https://www.youtube.com/channel/UC2XWJzPqJ77rqdWADC0WidQ' target='_blank'>
                <AiFillYoutube size={'24px'} />
              </a>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='mx-md-5 py-2 px-3'>
              <span className='text-light px-3 py-2 gaming-icon-group d-flex align-items-center gap-2 ms-auto'>
                <IoPaperPlaneOutline /> Notify me when the platform is up
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default GamingPage
