import React from "react";
import Tag from "../../../Components/Tags/Tag";
import { socialMedia } from "../../../Globals/socialMedia";

import ArrowIcon from "./Assets/arrow-up-right.svg";
import "./PlatformHelp.scss";

export function PlatformHelp() {
  return (
    <div id="platform-help-section">
      <div className="large-container">
        <div className="container__text row">
          <div className="container__text--left col-sm-6 col-12">
            <h2 className="font-size-40 font-iner-bold line-height-1-1 color-gradient-lipo">
              We're here to help you
            </h2>
          </div>
          <div className="container__text--right col-sm-6 col-12">
            <p className="font-iner-regular line-height-1-2 font-size-16 gray-color">
              We have live customer service to help you solve your problems. If
              you have questions related to your purchase and use, please
              contact us for an efficient response.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="card__container col-sm-6 col-md-3 col-12">
            <div className="card__container--head">
              <h3 className="font-size-24 font-iner-medium line-height-1-1 white-color ">Support</h3>
              <Tag
                                background="linear-gradient(45deg, #FE9F42, #E0FF36)"
                                color="#000"
                                uppertitle="Soon"
                                height="14px"
                                width="56px"
                                fontSize="9px"
                              />
            </div>
            <p className="font-iner-regular line-height-1-2 font-size-16 gray-color">
              Get the assistance you need with our support section. Contact us
              via live chat or email for prompt and reliable support.
            </p>
          </div>
          <a style={{color:"white",textDecoration:'none'}} target="_blank"  href={socialMedia.telegramGroup} className="card__container col-sm-6 col-md-3 col-12">
            <div className="card__container--head">
              <h3 className="font-size-24 font-iner-medium line-height-1-1 ">
                Comunity
              </h3>
              <img className="img-fluid" src={ArrowIcon} alt="ArrowIcon" />

            </div>
            <p className="font-iner-regular line-height-1-2 font-size-16 gray-color">
            Our community's goal is to help members connect, share ideas, and improve their skills through friendly competition and discussion.                      </p>
          </a>
          <a style={{color:"white",textDecoration:'none'}} target="_blank"  href={socialMedia.discord} className="card__container col-sm-6 col-md-3 col-12">
            <div className="card__container--head">
              <h3 className="font-size-24 font-iner-medium line-height-1-1 ">Discord</h3>
              <img className="img-fluid" src={ArrowIcon} alt="ArrowIcon" />
            </div>
            <p className="font-iner-regular line-height-1-2 font-size-16 gray-color">
              Join our Discord community and connect with other players. Share
              tips, strategies, and experiences, and latest updates.
            </p>
          </a>
          <a style={{color:"white",textDecoration:'none'}} target="_blank"  href={socialMedia.telegramchannel} className="card__container col-sm-6 col-md-3 col-12">
            <div className="card__container--head">
              <h3 className="font-size-24 font-iner-medium line-height-1-1 ">Telegram</h3>
              <img className="img-fluid" src={ArrowIcon} alt="ArrowIcon" />
            </div>
            <p className="font-iner-regular font-size-16 line-height-1-2 gray-color">
              Get the latest updates on new games, promotions, and events, and
              chat with other players in a secure and convenient way.
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default PlatformHelp;
