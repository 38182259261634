import React from "react";

import Tag from "../../../Components/Tags/Tag";
import "./PlatformCommission.scss";

export function PlatformCommission() {
  return (
    <div id="platform-commission-section">
      <div className="large-container">
        <div className="text-center txt-container d-flex flex-column ">
          <div className="d-flex m-auto">
            {" "}
            <Tag
              background="linear-gradient(45deg, #FF9C42, #C236FF)"
              color="#000"
              uppertitle="$AFFILIATE SYSTEM"
            />
          </div>
          <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-5">
            <span className="color-gradient-lipo ">10% Commission</span>
            <br></br>
            from all users registered by you
            <br /> & everything what they play.
          </h2>
        </div>
      </div>
    </div>
  );
}

export default PlatformCommission;
