import React from "react";
import "./FirstProject.scss";
import Button from "../../../Components/Button/Button";

const Card = ({ cardImg, cardTitle, cardParagraph, extraClass }) => {
  return (
    <div
      className={`success-buseiness-card white-color ${extraClass}  position-relative `}
    >
      <div className="d-flex justify-content-center img-sizer align-items-center">
        {" "}
        <img src={cardImg} alt="card-img" className="img-fluid  " />
      </div>
      <div className="success-buseiness-card-txt">
        <h4 className="font-size-24 font-iner-bold mt-3">{cardTitle}</h4>
        <p className="font-size-16 font-iner-regular light-grey line-height-1-4">
          {cardParagraph}
        </p>
      </div>
    </div>
  );
};

export default Card;
