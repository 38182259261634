import React from "react";
import { ReactComponent as ZixxarLogo } from "../../Assets/Global/zixxar-logo.svg";
import { ReactComponent as ArrowRight } from "../../Assets/Global/arrow-right.svg";
import "./ErrorPage.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../..//Assets//Global//SVG//logo.svg";


const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <section className="errorPage white-color">
      <div className="section-padding white-color">
        <div className="large-container">
          <div className="d-flex flex-column justify-content-center align-items-center error-container mt-5">
            <>
              </>
            <div className="d-flex align-items-center justiy-content-center flex-column">
              <h2 className="font-size-80 font-iner-bold my-3 my-md-5 text-center">Oops! Page not found.</h2>
              <button className="back-btn" onClick={() => navigate("/")}>
                <div className="inner-container font-size-24 font-pnm">
                  Go back home
                  <ArrowRight className="ms-2" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
