import React from "react";
import "./PlayWinEarn.scss";
import SliderGrab from "../../../Components/SliderGrab/SliderGrab";
import Tag from "../../../Components/Tags/Tag";
import ButtonOutline from "../../../Components/ButtonOutline/ButtonOutline";
import BoltIcon from "../../Platform/Assets/bolt.svg";
import token from "../Tokenomics/Assets/token_sus.png";

const PlayWinEarn = () => {
  return (
    <div className="playwinearn-section section-padding white-color mb-5">
      <div className="text-center txt-container d-flex  flex-column ">
        <div className="d-flex m-auto">
          {" "}
          <Tag
            background="linear-gradient(45deg, #FF9C42, #C236FF)"
            color="#000"
            uppertitle="LIVEPOT"
          />
        </div>
        <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-5">
          Play. Win. Earn.
        </h2>
      </div>
      <div className="large-container mb-5 pe-0">
        <SliderGrab>
          <div className="d-flex  position-relative margin-auto justify-content-center">
            <div className="success-buseiness-card white-color ">
              <div className="success-buseiness-card-txt text-center d-flex justify-content-center align-items-center h-100">
                <div className="m-auto">
                  <div
                    className={`title-component z-2 lh-1 font-iner-bold font-size-48 font-iner-bold`}
                  >
                    Stake $LIPO and <br></br>
                    <span className="gradient-pot ">Earn 20%</span>
                    <br></br>
                    from all LivePot commissions.
                  </div>
                </div>
                <p className="font-size-16 font-iner-regular light-grey line-height-1-4"></p>
              </div>
            </div>
            <div className="success-buseiness-card white-color background-lipo-gradient position-relative">
              <div className="success-buseiness-card-txt text-center d-flex justify-content-center align-items-center h-100 position-relative ">
                {/* <img
                  className="token-absolute position-absolute"
                  src={token}
                  alt=""
                /> */}

                {/* <div className="m-auto"> */}
                <div
                  className={`title-component title-component-middle z-2 lh-1 font-iner-bold font-size-56 font-iner-bold`}
                >
                  Get <span className="gradient-pot">$LIPO </span> <br></br>
                  in Presale
                  <br />
                  and
                  <br />
                  <span className="gradient-pot">
                    Win <br /> up to 20X <br />
                    credit value
                  </span>
                  <div className="m-auto d-flex w-100">
                    <ButtonOutline
                      title="Get $LIPO"
                      height={48}
                      heightResponsive={48}
                      heightResponsive580={40}
                      width={150}
                      color="#fff"
                      outlineColor="transparent"
                      bgColor="trasnparent"
                      hoverColor="#fff"
                      bgHoverColor="#E169A0"
                      icon={BoltIcon}
                      customClass="z-2 radius64 font-ri-m font-size-20 mt-5 m-auto "
                      callback={(e) => window.open("https://swap.livepot.com")}
                    />
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="success-buseiness-card white-color">
              <div className="success-buseiness-card-txt text-center d-flex justify-content-center align-items-center h-100">
                <div className="m-auto">
                  <div
                    className={`title-component z-2 lh-1 font-iner-bold font-size-48 font-iner-bold`}
                  >
                    <span className="gradient-pot">
                      Sleep & <br /> Earn 10%
                    </span>
                    <br></br>
                    from LivePot<br></br>
                    affiliate system.
                    <br></br>
                    <br></br>
                    Win{" "}
                    <span className="gradient-pot">Lifetime commission.</span>
                  </div>
                </div>
                <p className="font-size-16 font-iner-regular light-grey line-height-1-4"></p>
              </div>
            </div>
          </div>
        </SliderGrab>
      </div>
    </div>
  );
};

export default PlayWinEarn;
