import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Tag from "../../../../Components/Tags/Tag";
import Title from "../../../../Components/Title/Title";
import "./TokenomicsSlide.scss";
import tokenSmall from '..//../../../Globals/assets/token_small.png'


import token from "../Assets/token_sus.png";
const MAX_CHARS = 50;
const MAX_CHARS_TITLE = 100;

const TokenomicsLast = ({ ...props }) => {
  return (
    <div
      state={{ rerender: true }}
      className="slide radius16 overflow-hidden h-100 user-select-auto text-decoration-none "
      id="tokenomics-last"
    >
      <div className="position-relative h-100 d-flex flex-column">
        <div
          style={{ height: "50%", zIndex: "2" }}
          className="d-flex justify-content-center align-items-center position-relative background-half"
        >
          <div className={`corner-rounded ${props.color}`}></div>
          <div className="z-index d-flex justify-content-center align-items-center flex-column">
            <img
              style={{ maxHeight: "150px" }}
              src={token}
              alt="news img"
              className="img-fluid mb-4"
            />  <Tag
            background="linear-gradient(45deg, #FF9C42, #C236FF)" 
             color="#000"
         uppertitle={props.date}/>
  <br></br>
            <Title title={props.type} size="24" />



          </div>
        </div>
        <br></br>
        <div style={{background:"#00000057"}} className="d-flex p-md-3 p-2 position-relative main-content-container align-items-start justify-content-start gap-2 flex-column z-index ">
          <div className="d-flex w-100  align-items-center justify-content-between gap-2"></div>
          <div className="font-size-40 font-iner-bold white-color lh-1 text-center w-100">
            {/* {props.title.substring(0, MAX_CHARS_TITLE)}
          
          {props.title.length > MAX_CHARS_TITLE ? '...' : ''} */}
            <div className="font-iner-regular font-size-20 gray-color mb-2 text-center z-index">
            {props.priceText}
            </div>
<span className={`${props.color}-text`}>     {props.title}</span>
       
          </div>
        </div>
        <div className="d-flex justify-content-center flex-column align-items-center  m-auto z-index">
          <div className="font-iner-regular font-size-20 gray-color">
 {props.bottomText}
          </div>
          {props.color==='airdrop' ?  <div className="font-iner-bold font-size-32 text-center px-2">{props.subtitle}<img src={tokenSmall} alt="" /></div>:           <div className="font-iner-bold font-size-24 text-center px-2">{props.subtitle} {props.token ?<img src={tokenSmall} alt="" /> :null } </div>
}
        </div>
      </div>
    </div>
  );
};

export default TokenomicsLast;
