import React from "react";
import Card from "./Card";
import "./FirstProject.scss";

import img1 from "./Assets/investors.png";
import img2 from "./Assets/drop.png";
import img3 from "./Assets/passive.png";

import SliderGrab from "../../../Components/SliderGrab/SliderGrab";
import Tag from "../../../Components/Tags/Tag";

const FirstProject = () => {
  return (
    <section
      id="first-project-section"
      className="first-project section-padding white-color mb-lg-5 mb-0"
    >
      <div className="text-center txt-container d-flex flex-column ">
        <div className="d-flex m-auto">
          {" "}
          <Tag
            background="linear-gradient(45deg, #FF9C42, #C236FF)"
            color="#000"
            uppertitle="LIVEPOT"
          />
        </div>
        <h2 className="font-size-80 font-iner-bold line-height-1-1 mb-0">
          The first project
          <br></br>
          in the world.
        </h2>
      </div>
      <div className="large-container pe-0 ps-1500-0">
        <SliderGrab>
          <div className="d-flex justify-content-center align-items-center container-cards mb-5">
            <Card
              cardTitle="Protection for Holders."
              cardParagraph={
                <>
                  The world’s first project developed based on the European
                  Projects model. The successful execution of the project is
                  ensured through a Bank Guarantee Letter, which covers the
                  funds in case the project encounters issues or the company
                  developing it is unable to complete it.
                  <a
                    target="_blank"
                    href="https://martoms.com/custom-pools/livepot/"
                    className="text-decoration-none text-light px-4 py-2 d-block text-center rounded-pill mt-3 "
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(143, 46, 183) 0%, rgb(185, 115, 56) 100%) 0% 0% no-repeat padding-box padding-box",
                      color: "#fff",
                      height: "30px",
                      lineHeight: "15px",
                      fontSize: "14px",
                    }}
                  >
                    <span>Protection for holders</span>
                  </a>
                </>
              }
              cardImg={img1}
            />
            <Card
              extraClass="background-lipo-gradient"
              cardTitle="Pricing Drop Protection."
              cardParagraph="The Price Drop Protection feature safeguards your earnings from price slippage due to unforeseen circumstances. With this feature, you can have peace of mind knowing that your earnings will always be according to your work. "
              cardImg={img2}
            />
            <Card
              cardTitle="Passively Earn
              Money for a Lifetime."
              cardParagraph="LivePot will employ a standard affiliate system for our most-performing users that will ensure lifelong commissions from the new users brought by them into the platform."
              cardImg={img3}
            />
          </div>
        </SliderGrab>
      </div>
    </section>
  );
};

export default FirstProject;
