import React, { useState } from "react";
import "./TokenManagement.scss";
import { Row } from "react-bootstrap";

import Tag from "../../../Components/Tags/Tag";
import Title from "../../../Components/Title/Title";

// token management section

import community from "../Assets/tm-community.jpg";

import incentives from "../Assets/tm-incentives.jpg";
import minimum from "../Assets/tm-minimum.jpg";
import decrease from "../Assets/tm-decrease.jpg";
import remove from "../Assets/tm-remove.jpg";
import only from "../Assets/tm-only.jpg";
import eliminate from "../Assets/tm-eliminate.jpg";
import platform2 from "../Assets/platform_creates_demand.png";

const TokenManagement = () => {
  const [current, setCurrent] = useState(0);
  const imgArray = [
    community,
    decrease,
    minimum,
    remove,
    platform2,
    incentives,
    only,
    eliminate,
  ];

  const [data, setData] = useState([
    {
      id: 1,
      title: "Community creates demand",
      description:
        "This will increase the value of their credits on the platform. This change will result in an increase in Trade Volume, providing everyone with an opportunity to make cashouts.",
      current: true,
    },
    {
      id: 2,
      title: "Decrease total supply",
      description:
        "To reduce the Total supply and increase the $LIPO’s value, any unsold tokens from the presale period will be burned.",
      current: false,
    },
    {
      id: 3,
      title: "Minimum price limit",
      description:
        "Implementing a minimum price limit will prevent token owners from potentially lowering the token price by selling in large quantities.",
      current: false,
    },
    {
      id: 4,
      title: "Remove selling pressure",
      description:
        "During the first year, LivePot will not sell any tokens. Instead, all $LIPO earned by the platform will be burned to reduce the Circulating Supply and boost the token’s value.",
      current: false,
    },
    {
      id: 5,
      title: "Platform creates demand",
      description:
        "To increase the trading volumes on the platform, LivePot will buy back all winnings from users.",
      current: false,
    },
    {
      id: 6,
      title: "Incentives to play with $LIPO",
      description:
        "Every time a user makes a deposit to play using $LIPO will receive a reward and it will be eligible to receive exclusive prizes. LivePot will repurchase all bonuses from user exchanges in order to enhance the demand and price of the token. ",
      current: false,
    },
    {
      id: 7,
      title: "Only sold by its owners.",
      description:
        "Every time a user makes a deposit to play using $LIPO will receive a reward and it will be eligible to receive exclusive prizes. LivePot will repurchase all bonuses from user exchanges in order to enhance the demand and price of the token. ",
      current: false,
    },
    {
      id: 8,
      title: "Eliminate market manipulation.",
      description:
        "Every time a user makes a deposit to play using $LIPO will receive a reward and it will be eligible to receive exclusive prizes. LivePot will repurchase all bonuses from user exchanges in order to enhance the demand and price of the token. ",
      current: false,
    },
  ]);

  const handleClick = (item) => {
    setCurrent(item.id - 1);

    const newData = data.map((d) => {
      if (d.id === item.id) {
        return { ...d, current: true };
      } else {
        return { ...d, current: false };
      }
    });

    setData(newData);
  };
  return (
    <section id="TokenManagement-section">
      <div className="section-padding white-color">
        <div className="large-container">
          <div className="d-block d-lg-none"></div>
          <Row>
            <div className="col-lg-6 col-12 order-lg-1 order-2 d-flex justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center justify-content-lg-start align-items-lg-start develop-container">
                <Tag
                  background="linear-gradient(45deg, #FF9C42, #C236FF)"
                  color="#000"
                  uppertitle="TOKEN"
                />
                <h2 className="font-size-56 font-iner-bold line-height-1-1 mb-0 text-md-start text-center mb-3">
                  Token Management
                </h2>
                <div className="in-section-scroll">
                  {data.map((item, index) => (
                    <>
                      {" "}
                      <p
                        className={`${
                          item.current ? "active" : "innactive"
                        } font-size-16 mt-3   font-iner-regular position-relative light-grey text-center mb-3 text-lg-start line-height-1-4`}
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        {/* {displayData.description} */}
                        <hr className="position-absolute top-line"></hr>
                        <div className="py-3">
                          {" "}
                          <div className="mb-2">
                            {" "}
                            <Title size={24} title={item.title} />{" "}
                          </div>
                          {item.description}{" "}
                        </div>
                        <hr className="position-absolute"></hr>
                      </p>
                    </>
                  ))}
                </div>
              </div>
            </div>

            <div className=" col-lg-6 col-12 order-lg-2 order-1 pb-lg-0 pb-md-5 pb-0position-relative slider-col-height d-flex justify-content-center align-items-center">
              <img
                className="img-fluid mh-mob-img img"
                src={imgArray[current]}
                alt=""
              />
            </div>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default TokenManagement;
