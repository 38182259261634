import coin from './assets/livepot-logo.png'
import plug from './assets/plug.png'
import dealer from './assets/dealer.png'
import gameAquisition from './assets/gameAquisition.png'
import staking from './assets/staking.png'
export const blog = [
  {
    bg: coin,
    title: <> Closed</>,
    date: 'Apr-May 2023',
    date1: 'APRIL 1, 2023',
    date2: 'MAY 31, 2023',
    type: 'Private Presale',
    quantity: `8.694.400`,

    shortDescription: `15.000.000`,
  },

  {
    bg: coin,
    title: <>Closed </>,
    date: 'Jun-Jul 2023',
    date1: 'JUNE 1, 2023',
    date2: 'JULY 31, 2023',
    type: 'Public Presale Phase I',
    quantity: `18.650.000`,
    shortDescription: `25.000.000`,
  },
  {
    bg: coin,
    title: <>Closed</>,
    date: 'Aug-Sep 2023',
    date1: 'AUGUST 1, 2023',
    date2: 'SEPTEMBER 30, 2023',
    type: `Public Presale
    Phase II`,
    quantity: '14.200.000',

    shortDescription: `20.000.000`,
  },
  {
    bg: coin,
    title: <>2$ </>,
    date: 'Oct-Nov 2023',
    date1: 'OCTOBER 1, 2023',
    date2: 'NOVEMBER 30, 2023',
    type: `Public Presale
    Phase III`,
    quantity: '4.823.000',

    shortDescription: `15.000.000`,
  },
  {
    bg: coin,
    title: <>3$</>,
    date: 'DEC-JAN 2024',
    date1: 'DECEMBER 1, 2023',
    date2: 'JANUARY 31, 2024',
    type: `Public Presale
    Phase IV`,
    quantity: '0',

    shortDescription: `10.000.000`,
  },
  {
    bg: coin,
    title: <>4$ </>,
    date: 'FEB-MAR 2024',
    date1: 'FEBRUARY 1, 2024',
    date2: 'MARCH 31, 2024',
    type: `Public Presale
    Phase V`,
    quantity: '0',

    shortDescription: `10.000.000`,
  },
]

export const blogExtras = [
  {
    bg: coin,
    title: <>5$ </>,
    date: 'JUN 2024',
    type: `Platform
    Launch`,
    priceText: 'Minimum Value',
    bottomText: `Decrease Total Supply`,

    shortDescription: `All unsold $LIPO
    will be burned`,
  },
  {
    bg: coin,
    title: <>10$ </>,
    date: 'JUN 2025',
    type: `Restart`,

    priceText: 'Minimum Value',
    bottomText: `Decrease Total Supply`,
    shortDescription: `All  $LIPO earned by
    platform will be burned  `,
  },
  {
    bg: coin,
    title: <>Airdrop </>,
    date: 'APR 2023- APR 2024',
    type: `Marketing`,

    shortDescription: '5.000.000',
    priceText: 'Price',
    bottomText: `Allocated tokens`,
  },
]
