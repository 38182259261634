import React, { useRef, useState } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Tag from "../../../Components/Tags/Tag";
import SliderRight from "../../../Components/SliderRight/SliderRight";
import "./PlatformToggleContent.scss";
import ToogleCarousel1 from "./Assets/toggle-carousel-1.png";
import ToogleCarousel2 from "./Assets/toggle-carousel-2.png";
import ToogleCarousel3 from "./Assets/toggle-carousel-3.png";
import ToogleCarousel4 from "./Assets/toggle-carousel-4.png";
import ToogleCarousel5 from "./Assets/toggle-carousel-5.png";
import ToogleCarousel6 from "./Assets/toggle-carousel-6.png";

const TABS_CONFIG = [
  {
    key: "casino-games",
    title: "Casino Games",
    icon: ToogleCarousel1,
    hasBadge: false,
  },
  {
    key: "live-casino",
    title: "Live Casino",
    icon: ToogleCarousel2,
    hasBadge: false,
  },
  {
    key: "play-to-earn",
    title: "Play to Earn",
    icon: ToogleCarousel3,
    hasBadge: false,
  },
  {
    key: "multiplayer-games",
    title: "Multiplayer Games",
    icon: ToogleCarousel4,
    hasBadge: false,
  },
  {
    key: "p2p-bet-room",
    title: "P2P Bet Room",
    icon: ToogleCarousel5,
    hasBadge: true,
  },
  {
    key: "sporting-bets",
    title: "Sports Betting",
    icon: ToogleCarousel6,
    hasBadge: false,
  },
  {
    key: "livestream-bets",
    title: "LiveStream Bets",
    icon: ToogleCarousel6,
    hasBadge: true,
  },
  {
    key: "esports",
    title: "Esport",
    icon: ToogleCarousel6,
    hasBadge: false,
  },
  {
    key: "mystery-box",
    title: "Mistery Box",
    icon: ToogleCarousel6,
    hasBadge: false,
  },
];

const PlatformToggleContent = ({ tabKey, setKey }) => {
  const sliderRef = useRef(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);

  return (
    <div id="platform-toggle-content" className="">
      <div className="large-container">
        <SliderRight
          sliderRef={sliderRef}
          atStart={atStart}
          atEnd={atEnd}
          setAtEnd={setAtEnd}
          setAtStart={setAtStart}
        >
          <>
            <Tabs activeKey={tabKey} onSelect={(k) => setKey(k)} className="">
              {TABS_CONFIG.map((tab) => {
                return (
                  <Tab
                    key={tab.key}
                    eventKey={tab.key}
                    title={
                      <div className="tab-container">
                        {tab.icon && <img src={tab.icon} alt="Container" />}
                        <div className="tab-container--text">
                          {tab.hasBadge && (
                            <Tag
                              background="linear-gradient(270deg, #FF9C42 0%, #E0FF36 100%)"
                              color="#000"
                              uppertitle="UNIQUE"
                            />
                          )}
                          {tab.title}
                        </div>
                      </div>
                    }
                  ></Tab>
                );
              })}
            </Tabs>
          </>
        </SliderRight>
      </div>
    </div>
  );
};

export default PlatformToggleContent;
