import React from 'react';

const Title= ({ ...props })=> {
  return (
    <div
      className={`title-component z-2 lh-1 font-iner-bold font-size-${props.size}`}
      style={{ color: props.color }}
    >
      {props.title}
    </div>
  );
};

export default Title;
