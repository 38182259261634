import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Tag from "../../../../Components/Tags/Tag";
import Title from "../../../../Components/Title/Title";
import "./TokenomicsSlide.scss";
import tokenSmall from "..//../../../Globals/assets/token_small.png";

import token from "../Assets/token_sus.png";
const MAX_CHARS = 50;
const MAX_CHARS_TITLE = 100;

const TokenomicsFirst = ({ ...props }) => {
  return (
    <div
      state={{ rerender: true }}
      className="slide radius16 overflow-hidden h-100 user-select-auto text-decoration-none "
      id="tokenomics-first"
    >
      <div className="position-relative h-100 d-flex flex-column">
        <div
          style={{ height: "50%", zIndex: "2" }}
          className="d-flex justify-content-center align-items-center position-relative background-half"
        >
          <div className="corner-rounded"></div>
          <div className="z-index d-flex justify-content-center align-items-center flex-column">
            <img
              style={{ minHeight: "30%" }}
              src={token}
              alt="news img"
              className="img-fluid mb-4"
            />

            <br></br>
          </div>
        </div>
        <div className="d-flex p-3 position-relative main-content-container align-items-start justify-content-start gap-2 flex-column z-index">
          <div className="d-flex w-100  align-items-center justify-content-between gap-2"></div>
          <div className="font-size-56 font-iner-bold white-color lh-1 text-center w-100 color-gradient-lipo mt-4">
            {/* {props.title.substring(0, MAX_CHARS_TITLE)} 
          
          {props.title.length > MAX_CHARS_TITLE ? '...' : ''} */}
            $LIPO
          </div>
        </div>
        <div className="d-flex justify-content-center flex-column align-items-center  m-auto z-index">
          <div className="font-iner-regular font-size-20 gray-color">
            Allocated tokens
          </div>
          <div className="font-iner-bold font-size-32">
            100.000.000 <img src={tokenSmall} alt="" />
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default TokenomicsFirst;
