import React from "react";
import "./Tag.scss";

const Tag = ({ ...props }) => {
  return (
    <div
      id="tag"
      style={{
        background: props.background,
        height: props.height,
        width: props.width,
        fontSize: props.fontSize,
        right: "-12px",
      }}
      className="radius64 h-24 px-3 z-2 py-1 d-flex align-items-center justify-content-center font-iner-bold"
    >
      <span
        className=" font-ti-b text-uppercase"
        style={{ color: props.color }}
      >
        {props.uppertitle}
      </span>
    </div>
  );
};

export default Tag;
